import queryString from "query-string";
import moment from "moment";
import differenceInDays from "date-fns/differenceInDays";
import { Buffer } from "buffer";

// Icons
import ServiceInfoIcon from "../components/iconComponents/ServiceInfoIcon";
import ServiceInfoWarningIcon from "../components/iconComponents/ServiceInfoWarningIcon";

// Themes
import theme from "../themes/mdc-theme";

// Constants
import RolePermissions from "../constants/RolePermissions";
import { userRoles } from "../constants/userConstants";
import { COMPONENT_IDS } from "../constants/RolePermissions";

// Valid Email
export const validateEmail = (email) => {
  const emailCheck =
    /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i;

  if (!emailCheck.test(email)) {
    return false;
  }

  return true;
};

// Format the Date
export const formatDate = (date) => {
  if (!date) {
    return "";
  }

  let d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [day, month, year].join(".");
};

// DB Format the Date
export const dbFormatDate = (date) => {
  if (!date) {
    return "";
  }

  let d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
};

// Get the url parameter
export const getUrlParams = (url) => {
  let params = queryString.parse(url);
  return params;
};

// Get the Between Two Dates
export const getDateDifference = (startDate, endDate) => {
  return differenceInDays(new Date(endDate), new Date(startDate));
};

// Convert Month and Year
export const getFullDate = (monthYear) => {
  if (!monthYear) {
    return "";
  }

  const splittedMonth = monthYear.slice(0, 2);
  const splittedYear = monthYear.slice(2, 6);

  const date = new Date();

  const day = date.getDate();

  return `${splittedYear}-${splittedMonth}-${day}`;
};

// DB Month and year Format
export const getDBMonthYearFormat = (date) => {
  if (!date) {
    return "";
  }

  let d = new Date(date),
    month = "" + (d.getMonth() + 1),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;

  return [month, year].join("");
};

// Full Month Day and Year format
export const getMonthDayYearFullDate = (monthYear) => {
  if (!monthYear) {
    return "";
  }

  const splittedMonth = monthYear?.slice(0, 2);
  const splittedYear = monthYear?.slice(2, 6);

  const date = new Date();

  const day = date.getDate();

  return `${splittedMonth}-${day}-${splittedYear}`;
};

//This Function will return promise with the value of base 64 encoded string
export const getBase64String = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export const getBlobDataURL = (base64fileString, fileType) => {
  if ((base64fileString, fileType)) {
    const base64DecodedData = Buffer.from(base64fileString, "base64");
    const documentBlobData = new Blob([base64DecodedData], {
      type: `${
        fileType === "svg" ? "image/svg+xml" : "application/octet-stream"
      }`,
    });
    const documentBlobDataURL = URL.createObjectURL(documentBlobData);
    return documentBlobDataURL;
  } else return null;
};
export const statusMessagesColorScheme = {
  error: {
    color: theme.palette.MDCColors.color36,
    textColor: theme.palette.MDCColors.white,
    icon: <ServiceInfoWarningIcon />,
  },
  warning: {
    color: theme.palette.MDCColors.color37,
    textColor: theme.palette.MDCColors.black,
    icon: <ServiceInfoWarningIcon />,
  },
  info: {
    color: theme.palette.MDCColors.color23,
    textColor: theme.palette.MDCColors.black,
    icon: <ServiceInfoIcon />,
  },
};

export const getCurrentYear = () => {
  return new Date().getFullYear();
};
export const getCurrentMonthIndex = () => {
  return new Date().getMonth();
};

/**
 * Get Years Options
 *
 * @returns
 */
export const getYearOptions = () => {
  const currentYear = new Date().getFullYear();

  let yearOptions = [];
  for (let i = 2020; i <= currentYear; i++) {
    yearOptions.push({
      label: i,
      value: i,
    });
  }

  return yearOptions;
};

/**
 * Formatted Date
 *
 * @param {*} date
 * @returns
 */
export const formattedDate = (date) => {
  if (!date) {
    return "";
  }

  return moment(date).format("DD.MM.YYYY");
};

/**
 * Formatted Month And Year
 *
 * @param {*} date
 * @returns
 */
export const formattedMonthAndYear = (date) => {
  if (!date) {
    return "";
  }

  return moment(date).format("MMMM YYYY");
};

export function removeNullAndEmpty(obj) {
  for (const key in obj) {
    if (obj[key] === null || obj[key] === "") {
      delete obj[key];
    } else if (typeof obj[key] === "object") {
      removeNullAndEmpty(obj[key]); // Recursive call for nested objects
      if (Object.keys(obj[key]).length === 0) {
        delete obj[key];
      }
    }
  }
  return obj;
}

// Format date string in to DD.MM.YYYY
export function convertStringToDate(dateString) {
  return moment(dateString.split("+")[0]).format("DD.MM.YYYY");
}

// Get File Name From Http Response
export function getFileNameFromHttpResponse(httpResponse) {
  return (httpResponse && httpResponse?.headers["x-suggested-filename"]) || "";
}

// Has Role Permissions
export const hasRolePermissions = (page, action) => {
  const userDetails = JSON.parse(localStorage.getItem("user"));

  const userRolesArray = userRoles?.reduce((acc, roleDetails) => {
    acc.push(
      userDetails?.roles?.includes(roleDetails) &&
        RolePermissions[roleDetails]?.[page]?.includes(action)
    );
    return acc;
  }, []);

  return userRolesArray.some((value) => value === true);
};

export const getPermibasedonTab = (tabNum, subTab) => {
  let permibasedonTab = false;
  if (tabNum === 0) {
    permibasedonTab = hasRolePermissions(COMPONENT_IDS.PROFILE, "update");
  } else if (tabNum === 1) {
    if (subTab === 0) {
      permibasedonTab = hasRolePermissions(COMPONENT_IDS.GKS, "update");
    } else if (subTab === 1) {
      permibasedonTab = hasRolePermissions(COMPONENT_IDS.FORDERUNG, "update");
    } else if (subTab === 2) {
      permibasedonTab = hasRolePermissions(
        COMPONENT_IDS.DATEN_UR_Rechnungsabrechnung,
        "update"
      );
    }
  } else if (tabNum === 2) {
    if (subTab === 0) {
      permibasedonTab = hasRolePermissions(COMPONENT_IDS.BETRIEBE, "update");
    } else if (subTab === 1) {
      permibasedonTab = hasRolePermissions(
        COMPONENT_IDS.BANK_DETAILS,
        "update"
      );
    }
  } else if (tabNum === 3) {
    if (subTab === 0) {
      permibasedonTab = hasRolePermissions(COMPONENT_IDS.MITARBEITER, "update");
    } else if (subTab === 1) {
      permibasedonTab = hasRolePermissions(
        COMPONENT_IDS.MITARBEITERVERWALTUNG,
        "update"
      );
    }
  } else if (tabNum === 4) {
    permibasedonTab = hasRolePermissions(COMPONENT_IDS.SETTINGS, "update");
  }
  return permibasedonTab;
};

let activeUser = JSON.parse(localStorage.getItem("activeUser"));
export const getActiveUserTenantAlias = () => {
  //returning tenant alias name
  return activeUser?.value;
};

export const getActiveUserGroupId = () => {
  return activeUser?.groupID;
};
