export const ConfirmationPopupConstants = {
  REGISTRATION_POPUP_HEADING: "Einreichen",
  CONTENT_PART1:
    ' Mit dem Klick auf den Button "Bestätigen" wird ihr Vorgang an die zuständige Zulassungsbehörde weitergeleitet und damit die Zulassung Ihres Fahrzeugs kostenpflichtig initiiert.',
  CONTENT_PART2: " Möchten Sie den Antrag jetzt absenden?",
  CONTENT_PART3:
    ' Andernfalls klicken Sie auf den Button "Schließen", um zurück auf die Startseite zu gelangen.',
  CERTIFICATE_DETAIL_ERROR:
    "Ihr Unternehmen hat kein gültiges Zertifikat hinterlegt. Bitte kontaktieren Sie Ihren Administrator, um Ihrem Unternehmen ein gültiges Zertifikat beizufügen.",
};

export const AcceptTechnicalAliasConstants = {
  ALIAS_ACCEPT_HEADING: "Neuen Mandanten anlegen",
  ALIAS_ACCEPT_MESSAGE:
    "Bitte überprüfen Sie die Angaben und geben sie für die Anlage eines neuen Mandanten frei oder lehnen sie diese ab.",
};

export const DeleteVollmachtPOARecordsConstants = {
  DELETE_MESSAGE_MULTIPE:
    "Möchten Sie die ausgewählten Vollmachten löschen? Datensätze, die diese Daten verwenden, bleiben erhalten.",
  DELETE_MESSAGE_SINGLE:
    "Möchten Sie die ausgewählte Vollmacht löschen? Datensätze, die diese Daten verwenden, bleiben erhalten.",
};

export const ErrorDetailsDialogConstants = {
  ERROR_DETAILS_POPUP_HEADING: "Fehlerdetails",
  ERROR_DETAILS_STATEMENT: `Fehler wurden in diesem Eintrag gefunden. Bitte klicken Sie auf „Details anzeigen“, um die Einzelheiten der Fehler zu sehen.`,
  ERROR_DETAILS_WITH_HASH_STATEMENT: `weitere Fehlermeldungen wurden gefunden. Bitte klicken Sie auf „Details anzeigen“, um die Einzelheiten der Fehler zu sehen.`,
};
