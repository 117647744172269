import React from "react";
// Material UI Components
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";

import { makeStyles } from "@mui/styles";
import { Grid } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  radioOptionsWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: "#E6E6E64D",
    padding: "2rem 1rem",
    marginBottom: "1rem",
  },
  defaultRadioButton: {
    "& .MuiTypography-root.MuiFormControlLabel-label": {
      marginLeft: "0.938rem",
      color: theme.palette.MDCColors.color18,
      fontSize: "1rem",
      fontFamily: theme.palette.typography.fontFamily,
      "&.Mui-disabled": {
        color: theme.palette.MDCColors.color18,
      },
    },
    "& .MuiSvgIcon-root": { color: theme.palette.MDCColors.color23 },
  },
  disabledRadioButton: {
    "& .MuiSvgIcon-root": { color: theme.palette.MDCColors.color18 },
  },
  duplicateRadioButton: {
    "& .MuiSvgIcon-root": { color: theme.palette.MDCColors.color34 },
  },
}));

const MDCRadioButtonWithIcon = (props) => {
  const {
    name,
    selected,
    onChange,
    options,
    disabled,
    duplicate,
    iconComponent,
  } = props;
  const {
    defaultRadioButton,
    disabledRadioButton,
    duplicateRadioButton,
    radioOptionsWrapper,
  } = useStyles();

  let MDCStyles = defaultRadioButton;

  if (disabled && !duplicate) {
    MDCStyles = `${MDCStyles} ${disabledRadioButton}`;
  }

  if (duplicate) {
    MDCStyles = `${MDCStyles} ${duplicateRadioButton}`;
  }

  return (
    <FormControl fullWidth>
      <RadioGroup value={selected} name={name}>
        {options?.map((option) => (
          <Grid
            key={option?.value}
            onChange={onChange}
            className={radioOptionsWrapper}
          >
            <FormControlLabel
              value={option?.value}
              control={<Radio />}
              className={MDCStyles}
              label={option?.label}
              disabled={disabled}
            />
            {option?.isIconPresent ? iconComponent : null}
          </Grid>
        ))}
      </RadioGroup>
    </FormControl>
  );
};

export default MDCRadioButtonWithIcon;
