module.exports = {
  WEITERLEITEN: "weiterleiten",
  WEITERLEITEN_HEADING: "Weiterleiten abschließen",
  WEITERLEITEN_CONTENT:
    "Ihr Vorgang wird an die Zulassungsbehörde weitergeleitet.Die Zulassung Ihres Fahrzeugs wird kostenpflichtig initiiert",
  WEITERLEITEN_CONFIRMATION: " Möchten Sie den Vorgang jetzt weiterleiten?",
  ABLAGE: "ablage",
  ABLAGE_HEADING: "Vorgang wurde erfolgreich abgespeichert!",
  ABLAGE_CONTENT:
    "Sie können den Vorgang im Dashboard verfolgen und später weiterbearbeiten.",
  ERROR: "error",
  ERROR_CONTENT:
    "Es können nur Anträge im Status versandfertig an die Zulassungsstelle versendet werden. Bitte überprüfen Sie Ihre Auswahl und versuchen es erneut.",
  ERROR_CONTENT_MANDATORY:
    "Es fehlen noch notwendige Informationen um diesen Vorgang in die Ablage zu legen. Bitte befüllen Sie mindestens die externe Vorgangsnummer.",
  ABORT: "abort",
  ABORT_HEADING: "Sind Sie sicher, dass Sie diesen Vorgang verwerfen möchten?",
  ABORT_CONTENT:
    "Ihre Eingaben für diesen Antrag sind noch nicht gespeichert. Mit dem Verlassen dieser Siete werden ihre eingetragnen Informationen gelöscht. Möchten Sie den Antrag vor dem Verlassen speichern?",
  ADMIN_INFO_COPY_ABORT: "adminInfoCopyabort",
  ADMIN_INFO_COPY_HEADING:
    "Sind Sie sicher, dass Sie die Bankinformationen überschreiben wollen?",
  ADMIN_INFO_COPY_CONTENT:
    "Ihre Eingaben für diesen Antrag sind noch nicht gespeichert. Mit dem Verlassen dieser Siete werden ihre eingetragnen Informationen gelöscht. Möchten Sie den Antrag vor dem Verlassen speichern?",
  REQUEST_TO_WEITERLEITEN: "requestToWeiterleiten",
  QR_CODE: "QRCode",
  QR_CODE_HEADING: "Neuen Vollmachtsprozess starten",
  QR_CODE_CONTENT_FIRST:
    "Mit dem Generieren eines neuen QR Codes starten Sie einen neuen QES Vollmachtsprozess.Alle Angaben, der aktuellen Verimi QES Session werden somit gelöscht.",
  QR_CODE_CONTENT_SECOND:
    "Möchten Sie einen neuen QES Vollmachtsprozess starten?",
  EMPTY_PASSWORD_VALIDATION:
    "Passwort oder neues Passwort darf nicht leer sein.",
  PASSWORD_REPEAT_VALIDATION:
    "Neues Passwort und wiederholen passwort stimmen nicht überein.",
  NOTIFICATION_DELETE_FAILURE: "Unable to delete notification, please retry !!",
  AUTHENTICATION_FAILED: "Authentifizierung ist fehlgeschlagen!.",
  UNEXPECTED_SERVER_ERROR: `Unerwarteter Fehler auf dem Server!.`,
  ACCESS_DENIED: `Zugang verweigert.`,
  ORGANIZATION_SWITCHED: `Organisation angepasst.`,
  ORGANIZATION_SWITCHED_ERROR: `Sie haben keine Berechtigungen.`,
  SERVICE_ERROR:
    "Es tut uns leid! Es ist ein Fehler er Services aufgetreten. Bitte versuchen Sie es später noch einmal",
  TECHNICAL_FAILURE: "Technischer Fehler",
  BANK_INFO_MISSING_ERROR:
    "Es fehlen noch notwendige Informationen Ihres Mandanten, bitte kontaktieren Sie Ihren Administrator, um diese Informationen zu vervollständigen.",
  EMP_ID_MISSING_ERROR:
    "Bitte ergänzen Sie Ihre Mitarbeiternummer, um einen Vorgang weiterzuleiten.",
  DELETE_OPERATION_ID_CONFIRMATION:
    "Sind Sie sicher, dass Sie diese Mitarbeiternummer löschen möchten? \n Die mit dieser Nummer verknüpften Vorgänge können anschließend nicht übermittelt werden.",
  SCHLIEBEN_TEXT_QUESTION:
    "Sind Sie sicher, dass Sie diesen Vorgang ohne Speichern schließen möchten? ",
  SCHLIEBEN_TEXT:
    'Mit dem Klick auf "Bestätigen" werden Ihre letzten Änderungen nicht übernommen.',
  VERWERFEN_TEXT_QUESTION:
    "Sind Sie sicher, dass Sie diesen Vorgang verwerfen möchten? ",
  VERWERFEN_TEXT:
    'Mit dem Klick auf "Bestätigen" wird Ihr Vorgang gelöscht und kann nicht mehr nachträglich bearbeitet oder eingereicht werden.',
  KENNZEICHEN_ERROR_MSG: "Bitte geben Sie ein gültiges Kennzeichenformat ein.",
  TENANT_INFO_MISSING_ERROR: "Die Mandanteninformationen sind unvollständig.",
  ACTIVATE_POPUP_INFO:
    'Möchten Sie den Nutzer aktivieren? Dann klicken Sie bitte auf "Bestätigen", andernfalls klicken Sie auf "Abbrechen".',
  DEACTIVATE_POPUP_INFO:
    'Möchten Sie den Nutzer blockieren? Dann klicken Sie bitte auf "Bestätigen", andernfalls klicken Sie auf "Abbrechen".',
  DELETE_POPUP_INFO:
    'Möchten Sie den Nutzer löschen? Dann klicken Sie bitte auf "Bestätigen", andernfalls klicken Sie auf "Abbrechen".',
  ACTIVATE_NACHRICHT_POPUP_INFO:
    'Die ausgewählte Nachricht wird mit dem Klick auf den Button "Bestätigen" aktiviert.',
  DEACTIVATE_NACHRICHT_POPUP_INFO:
    'Die ausgewählte Nachricht wird mit dem Klick auf den Button "Bestätigen" deaktiviert.',
  DELETE_NACHRICHT_POPUP_INFO:
    'Die ausgewählte Nachricht wird mit dem Klick auf den Button "Bestätigen" gelöscht.',
};
