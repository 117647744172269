import axios from "axios";
import { toast } from "react-toastify";
import NotificationMessage from "../../../components/core/NotificationMessage";

// Constants
import {
  AUTHENTICATION_FAILED,
  UNEXPECTED_SERVER_ERROR,
  SERVICE_ERROR,
  TECHNICAL_FAILURE,
  ACCESS_DENIED,
} from "../../../constants/messageConstants";

// Helper
import { history } from "../../../helpers/history";

//let accessToken;

export default function interceptor() {
  //const Token = localStorage.getItem("accessToken");
  /*if (Token) {
    accessToken = `Bearer ${Token}`;
    axios.defaults.headers.common["Authorization"] = accessToken;
  }*/

  // axios.defaults.headers.common.accept = "application/json";
  // axios.defaults.headers.common["Content-Type"] = "application/json"
  // axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";

  // axios.defaults.headers.common.accept = "application/json";
  // axios.defaults.headers.common["Content-Type"] = "application/json"
  // axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";

  axios.defaults.withCredentials = true;
  axios.interceptors.request.use(
    (config) => {
      config.headers.common.accept = "application/json";
      config.headers.common["X-Requested-With"] = "XMLHttpRequest";
      const activeUser = JSON.parse(localStorage.getItem("activeUser"));

      if (activeUser) {
        config.headers.common["X-Group-ID"] = activeUser?.groupID;
        config.headers.common["X-Tenant-Alias"] = activeUser?.value;
      }
      //will update these conditions once GroupId and TenantAlias got in sync
      if ("GroupId" in config.headers && "TenantAlias" in config.headers) {
        config.headers.common["X-Group-ID"] = config.headers.GroupId;
        config.headers.common["X-Tenant-Alias"] = config.headers.TenantAlias;
      } else if (
        "Groupid" in config.headers &&
        "Tenantalias" in config.headers
      ) {
        config.headers.common["X-Group-ID"] = config.headers.Groupid;
        config.headers.common["X-Tenant-Alias"] = config.headers.Tenantalias;
      } else if (
        "groupId" in config.headers &&
        "tenantAlias" in config.headers
      ) {
        config.headers.common["X-Group-ID"] = config.headers.groupId;
        config.headers.common["X-Tenant-Alias"] = config.headers.tenantAlias;
      }

      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      let errorDetails = { ...error };
      let errorMessage;
      const isErrorJsonType =
        error?.response?.data instanceof Blob &&
        error?.response?.data?.type === "application/json";

      switch (errorDetails?.response?.status) {
        case 400: //Invalid credentioals
          let data;
          if (isErrorJsonType) {
            data = JSON.parse(await error.response.data.text());
          } else {
            data = errorDetails.response.data;
          }
          errorMessage =
            data && data.message ? data.message : AUTHENTICATION_FAILED;
          toast.error(<NotificationMessage message={errorMessage} />);
          break;
        case 401: //token expired
          localStorage.clear();
          // Redirect Login
          history?.push("/login");
          /* eslint no-restricted-globals:0 */
          //window.location.reload(true);
          break;
        case 500: //server error
          if (isErrorJsonType) {
            data = JSON.parse(await error.response.data.text());
          } else {
            data = errorDetails.response.data;
          }
          errorMessage =
            data && data.message ? data.message : UNEXPECTED_SERVER_ERROR;
          toast.error(<NotificationMessage message={errorMessage} />);
          break;
        case 403:
          toast.error(<NotificationMessage message={ACCESS_DENIED} />);
          break;
        case 503: //Service Unavailable
          if (isErrorJsonType) {
            data = JSON.parse(await error.response.data.text());
          } else {
            data = errorDetails.response.data;
          }
          errorMessage = data && data.message ? data.message : SERVICE_ERROR;
          toast.error(<NotificationMessage message={errorMessage} />);
          break;
        default:
          if (isErrorJsonType) {
            data = JSON.parse(await error.response.data.text());
          } else {
            data = errorDetails.response.data;
          }
          errorMessage =
            data && data.message ? data.message : TECHNICAL_FAILURE;
          toast.error(<NotificationMessage message={errorMessage} />); // Default error if we are not getting status text from backend
      }
      return Promise.reject({ ...error });
    }
  );
}
