
// Constants
import { userConstants } from "../../../constants/userConstants";

// Service
import { accountManagementService } from "../../services/userAccount/accountManagementService";

// Components
import { HTTP_SUCCESS_STATUS_CODES } from "../../../constants/VorgangDetailsConstants";

function getAvailableTenantListSuccess(responseData) {
  return { type: userConstants.GET_TENANT_LIST_SUCCESS, responseData };
}

function getTenantAlias(group) {
  return group["path"].split("/")[4];
}

function getGroupID(group) {
  return group["path"].split("/")[2];
}

function checkIfHomebasedTenant(group) {
  const user = JSON.parse(localStorage.getItem("user"));

  const customerArray = group["path"].split("/");

  if (customerArray?.includes("Customer")) {
    return customerArray[4] === user?.pTenantAlias ? true : false;
  }

  return false;
}

function getAvailableTenantList(sId) {
  return async (dispatch) => {
    return accountManagementService.getAvailableTenantList(sId).then((resp) => {
      if (HTTP_SUCCESS_STATUS_CODES?.includes(resp?.status)) {
        const loginOptions = resp?.data?.reduce((acc, tenant) => {
          if (tenant?.group?.path?.split("/").includes("Customer")) {
            acc.push({
              label: tenant?.tenantName,
              value: getTenantAlias(tenant?.group),
              groupID: getGroupID(tenant?.group),
              isIconPresent: checkIfHomebasedTenant(tenant?.group),
            });
          }
          return acc;
        }, []);
        dispatch(getAvailableTenantListSuccess(loginOptions));
        return loginOptions;
      }
    });
  };
}

export const accountManagementActions = {
  getAvailableTenantListSuccess,

  getAvailableTenantList,
};
