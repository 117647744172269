import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";
import React from "react";
import carLogo from "../../assets/images/my_digital_car.svg";
import MDCButton from "../core/MDCButton";
import MDCModal from "../core/MDCModal";
import ErrorFilterIcon from "../iconComponents/ErrorFilterIcon";

const useStyles = makeStyles((theme) => ({
  headerCss: {
    padding: "2rem",
    alignItems: "center",
  },
  headerColor: { color: theme.palette.MDCColors.black },
  contentCss: {
    fontFamily: theme.palette.typography.fontFamily,
    color: theme.palette.MDCColors.color15,
    padding: "0 2rem",
  },
}));
const ErrorDetailsDialog = (props) => {
  const { headerCss, headerColor, contentCss } = useStyles();

  const { popUpHeading, errorTextArray, show, handlePopupClose } = props;
  const getModalHeader = () => {
    return (
      <Grid container className={headerCss}>
        <Grid item xs={8} className={headerColor}>
          <h2>{popUpHeading}</h2>
        </Grid>
        <Grid item xs={4}>
          <img alt="My Digital Car" src={carLogo} width="95%" />
        </Grid>
      </Grid>
    );
  };

  const getModalBody = () => {
    return (
      <Grid container className={contentCss} overflow={"auto"}>
        {errorTextArray?.map((errorMessageDetails) => {
          return (
            <Grid container marginBottom={"1rem"}>
              <Grid item xs={1}>
                <ErrorFilterIcon />
              </Grid>
              <Grid item xs={11}>
                {errorMessageDetails?.message}
                {<br />}
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    );
  };
  const getModalFooter = () => {
    return (
      <Grid container pb={3} paddingX={"2rem"} justifyContent="space-between">
        <Grid item>
          <MDCButton
            onClick={handlePopupClose}
            variant="outlined"
            label="Schließen"
          />
        </Grid>
      </Grid>
    );
  };
  return (
    <div>
      <MDCModal
        open={show}
        title={getModalHeader()}
        body={getModalBody()}
        footer={getModalFooter()}
        size="md"
      />
    </div>
  );
};

export default ErrorDetailsDialog;
