import { config } from "../config";
import axios from "axios";

function getAvailableTenantList(sId) {
  const url = `${config.apiUrl}/users/${sId}/accessible-tenants`;
  return axios
    .get(url)
    .then((resp) => {
      return resp;
    })
    .catch((error) => {
      return error;
    });
}

export const accountManagementService = {
  getAvailableTenantList,
};
