import { userConstants } from "../../../constants/userConstants";

const initialState = {};

export default (state, action) => {
  if (typeof state === "undefined") {
    state = initialState;
  }
  switch (action.type) {
    case userConstants.GET_TENANT_LIST_SUCCESS:
      return Object.assign({}, state, {
        tenantList: action.responseData,
      });

    default:
      return state;
  }
};
