import { formConstants } from "../helpers/vorgange";

export const manualRequestFormConstants = {
  CREATE_MANUAL_ENTRY_SUCCESS: "CREATE_MANUAL_ENTRY_SUCCESS",
  VOLLMACHT_TAB_VALIDATION_SUCCESS: "VOLLMACHT_TAB_VALIDATION_SUCCESS",
  VOLLMACHT_TAB_QRCODE_SUCCESS: "VOLLMACHT_TAB_QRCODE_SUCCESS",
  VOLLMACHT_SIGNING_STATUS_SUCCESS: "VOLLMACHT_SIGNING_STATUS_SUCCESS",
  VOLLMACHT_SIGNED_SEPA_SUCCESS: "VOLLMACHT_SIGNED_SEPA_SUCCESS",
  VOLLMACHT_SIGNED_POA_SUCCESS: "VOLLMACHT_SIGNED_POA_SUCCESS",
  ZB_TEIL1_DOC_SUCCESS: "ZB_TEIL1_DOC_SUCCESS",
  ZB_TEIL2_DOC_SUCCESS: "ZB_TEIL2_DOC_SUCCESS",
  VOLLMACHT_SIGNED_POA_FAILURE: "VOLLMACHT_SIGNED_POA_FAILURE",
  VOLLMACHT_SIGNED_PROCESS_RESET: "VOLLMACHT_SIGNED_PROCESS_RESET",
  // Delete Vollmacht Loschen
  VOLLMACHT_LOSCHEN_SUCCESS: "VOLLMACHT_LOSCHEN_SUCCESS",
  DOWNLOAD_WIRTSCHAFTS_PDF_SUCCESS: "DOWNLOAD_WIRTSCHAFTS_PDF_SUCCESS",
  GET_WIRTSCHAFTS_DATA_SUCCESS: "GET_WIRTSCHAFTS_DATA_SUCCESS",
  GET_STEUERBEFREIUNG_DATA_SUCCESS: "GET_STEUERBEFREIUNG_DATA_SUCCESS",
};

// Vollmacht Tab Constants
export const VollmachtFormConstants = {
  STEP_ONE_CONTENT: "Bitte scannen Sie den angezeigten QR-Code mit der Verimi-App auf Ihrem iPad",
  STEP_TWO_CONTENT:
    "In diesem Prozessschritt werden die Vollmacht und das SEPA-Mandat von Ihren Kund*innen via Verimi auf ihrem iPad bestätigt. Bitte folgen Sie den Schritten der über den QR Code geöffneten Anwendung.",
  STEP_THREE_CONTENT:
    "Sobald Ihr Kunde der Bevollmächtigung auf dem iPad zugestimmt hat, werden die Dokumente in der manuellen Eingabe angezeigt.",
  STEP_ONE_REMOTE_CONTENT: "Initiierung des Remote-Prozesses",
  STEP_TWO_REMOTE_CONTENT: "Remote-Identifizierungslink an User schicken",
};

// Halter Request Form Constants
export const halterRequestFormConstants = {
  ADDRESS_FIELDS_BLANK_INFO:
    'Den Namen und die Adresse des Halters bzw. der Halterin können Sie im Prozessschritt der Vollmachtserstellung ausfüllen. Vervollständigen Sie alle relevanten Informationen in der manuellen Eingabe und starten Sie den Vollmachtsprozess im Reiter "Vollmacht" dieses Antrags.',
  PPOA_VOLLMACHT_INFO:
    "Wenn Sie die Angaben bearbeiten wollen, wird die hinterlegte Dauervollmacht in dem Vorgang gelöscht und es muss eine neue Vollmacht erstellt werden.",
  PPOA_VOLLMACHT_DISCONNECT: "Wollen Sie die Angaben wirklich bearbeiten?",
};

export const VOLLMACHT_TOOLTIP_MESSAGES = {
  QES_COMPLETED_MESSAGE:
    "In der Volmacht erfasst. Zum Bearbeiten bitte Vollmacht loschen",
  VOLLMACHTRELEVANT: "Vollmachtsrelevant",
  QES_COMPLETED_TAB_TOOLTIP: "Angaben verifiziert",
  KUNDENNR_BEI_DER_ZULASSUNGSSTELLE_TOOLTIP:
    "Bitte tragen Sie hier, soweit vorhanden, ihre individuelle, zulassungsspezifisch vereinbarte Kundennummer ein.",
  EXTERNE_VORGANGSNUMMER:
    "Die externe Vorgangsnummer muss eine eindeutige Nummer sein. Falls nicht vorhanden, wird diese für Sie mit einem Klick auf 'Nein' generiert.",
};
export const personTypeOptions = [
  {
    label: "Natürliche Person",
    value: "naturliche",
  },
  {
    label: "Juristische Person",
    value: "juristische",
  },
];

export const yesORnoOptions = [
  {
    label: "Ja",
    value: "ja",
  },
  {
    label: "Nein",
    value: "nein",
  },
];

export const qesIdentOptions = [
  {
    label: "Remote-Identifizierung",
    value: "REMOTE",
  },
  {
    label: "Local-Identifizierung",
    value: "LOCAL",
  },
];
export const poaOptions = [
  {
    label: "Dauervollmacht",
    value: "Dauervollmacht",
  },
  {
    label: "Einzelvollmacht",
    value: "Einzelvollmacht",
  },
];

export const KENNZEICHEN_OPTIONS_0 = 0;
export const KENNZEICHEN_OPTIONS_1 = 1;

export const kennzeichenZuweisenRadioOptions = [
  {
    label: "Nächstes freies Kennzeichen",
    value: KENNZEICHEN_OPTIONS_0,
  },
  {
    label: "Reserviertes Wunschkennzeichen",
    value: KENNZEICHEN_OPTIONS_1,
  },
];

export const vorgangsartOptions = [
  {
    label: "Neuzulassung",
    value: "NZ",
  },
  {
    label: "Tageszulassung",
    value: "TZ",
  },
  {
    label: "Außerbetriebsetzung",
    value: "AB",
  },
  {
    label: "Ummeldung",
    value: "UM",
  },
  {
    label: "Wiederzulassung",
    value: "WZ",
  },
  {
    label: "Namens- oder Adressänderung des Halters",
    value: "HA",
  },
  {
    label: "Offen",
    value: "SO",
  },
];
export const ZBTStaticOptions = [
  {
    label: "Adresse des Halters",
    value: "adresseDesHalters",
  },
  {
    label: "Individuelle Adresse eingeben",
    value: "individuelleAddresse",
  },
];
export const zbtNameTypeOptions = [
  {
    label: "Unternehmensname",
    value: "juristische",
  },
  {
    label: "Personenname",
    value: "naturliche",
  },
];

export const ZBTADDRESSFIELDS = {
  zbt1AddressType: {
    unternehmen: "zbt1Unternehmen",
    strasse: "zbt1Strasse",
    stadt: "zbt1Stadt",
    hausnummer: "zbt1Hausnummer",
    postleitzahl: "zbt1Postleitzahl",
  },
  zbt2AddressType: {
    unternehmen: "zbt2Unternehmen",
    strasse: "zbt2Strasse",
    stadt: "zbt2Stadt",
    hausnummer: "zbt2Hausnummer",
    postleitzahl: "zbt2Postleitzahl",
  },
};
export const ZBTADDRESSFIELDSPOA = {
  zbt1AddressType: {
    unternehmen: "zbt1Unternehmen",
    strasse: "zbt1Strasse",
    stadt: "zbt1Stadt",
    houseNummer: "zbt1Hausnummer",
    postleitzahl: "zbt1Postleitzahl",
  },
  zbt2AddressType: {
    unternehmen: "zbt2Unternehmen",
    strasse: "zbt2Strasse",
    stadt: "zbt2Stadt",
    houseNummer: "zbt2Hausnummer",
    postleitzahl: "zbt2Postleitzahl",
  },
};

export const ZBT1ALLATTRIBUTES = [
  "zbt1ZustellungType",
  "zbt1AddressType",
  "zbt1IndividualAddresseType",
  "zbt1PersonVorname",
  "zbt1PersonNachname",
  "zbt1Unternehmen",
  "zbt1Strasse",
  "zbt1Stadt",
  "zbt1Hausnummer",
  "zbt1Postleitzahl",
];

export const ZBT2ALLATTRIBUTES = [
  "zbt2ZustellungType",
  "zbt2AddressType",
  "zbt2IndividualAddresseType",
  "zbt2PersonVorname",
  "zbt2PersonNachname",
  "zbt2Unternehmen",
  "zbt2Strasse",
  "zbt2Stadt",
  "zbt2Hausnummer",
  "zbt2Postleitzahl",
];

export const IndividualAddressFieldsZB1 = [
  { fieldId: "zbt1Strasse", fieldLabel: "Straße", maxLength: 40 },
  { fieldId: "zbt1Hausnummer", fieldLabel: "Nummer", maxLength: 25 },
  { fieldId: "zbt1Postleitzahl", fieldLabel: "Postleitzahl", maxLength: 10 },
  { fieldId: "zbt1Stadt", fieldLabel: "Stadt", maxLength: 40 },
];

export const IndividualAddressFieldsZB2 = [
  { fieldId: "zbt2Strasse", fieldLabel: "Straße", maxLength: 40 },
  { fieldId: "zbt2Hausnummer", fieldLabel: "Nummer", maxLength: 25 },
  { fieldId: "zbt2Postleitzahl", fieldLabel: "Postleitzahl", maxLength: 10 },
  { fieldId: "zbt2Stadt", fieldLabel: "Stadt", maxLength: 40 },
];

export const IndividualAddressFieldsZB1POA = [
  { fieldId: "strasse", fieldLabel: "Straße", maxLength: 40 },
  { fieldId: "houseNummer", fieldLabel: "Nummer", maxLength: 25 },
  { fieldId: "postleitzahl", fieldLabel: "Postleitzahl", maxLength: 10 },
  { fieldId: "stadt", fieldLabel: "Stadt", maxLength: 40 },
];

export const IndividualAddressFieldsZB2POA = [
  { fieldId: "strasse", fieldLabel: "Straße", maxLength: 40 },
  { fieldId: "houseNummer", fieldLabel: "Nummer", maxLength: 25 },
  { fieldId: "postleitzahl", fieldLabel: "Postleitzahl", maxLength: 10 },
  { fieldId: "stadt", fieldLabel: "Stadt", maxLength: 40 },
];

export const verwendungFahrzeugOptions = [
  {
    label: "",
    value: "",
  },
  {
    label: "Selbstfahrermietfahrzeug",
    value: formConstants?.Selbstfahrermietfahrzeug,
  },
  // Commenting these options for now might be used in upcoming stories

  // {
  //   label: "Krankenwagen",
  //   value: formConstants.Krankenwagen,
  // },
  // {
  //   label: "Linienbus",
  //   value: formConstants.Linienbus,
  // },
  // {
  //   label: "Mietfahrzeug",
  //   value: formConstants.Mietfahrzeug,
  // },
  // {
  //   label: "Taxi",
  //   value: formConstants.Taxi,
  // },
  // {
  //   label: "sonstige Verwendung",
  //   value: formConstants["sonstige Verwendung"],
  // },
];
export const inspectionTypeOptions = [
  {
    label: "Hauptuntersuchung (HU)",
    value: "HU",
  },
  {
    label: "Sicherheitsüberprüfung (SP)",
    value: "SP",
  },
];
export const deRegistrationTypeOptions = [
  {
    label: "Fahrzeug wird verschrottet",
    value: "0",
  },
  {
    label: "keine Verwertung",
    value: "1",
  },
  {
    label: "Fahrzeug wird exportiert",
    value: "2",
  },
];
export const steuerzahlweiseOptions = [
  {
    label: "",
    value: "",
  },
  {
    label: "jährlich",
    value: "jährlich",
  },
  {
    label: "Halbjaehrlich",
    value: "halbjaehrlich",
  },
  {
    label: "Vierteljaehrlich",
    value: "vierteljaehrlich",
  },
  {
    label: "Steuerfrei",
    value: "steuerfrei",
  },
];
export const zbAddressZustellungOptions = [
  {
    label: "Versandadresse",
    value: "VERSAND",
  },
  {
    label: "Abholung durch",
    value: "ABHOLUNG",
  },
];
export const documentNames = {
  dauervollmacht: { fileName: "Vollmacht", id: "1" },
  sepaMandat: { fileName: "SEPA-Mandat", id: "2" },
};

export const PPOAdocumentNames = {
  dauervollmacht: { fileName: "Dauervollmacht", id: "1" },
  sepaMandat: { fileName: "SEPA-Mandat", id: "2" },
};

export const ZBGENERICERROR = "Bitte treffen Sie eine Auswahl.";

export const zb1zb2InfoMessage = `Zur Anzeige der angelegten Adressen, speichern Sie bitte den gesamten Vorgang mit dem Button "Speichern & Prüfen".`;

// Gender Dropdown Options
export const GeschlechtOptions = [
  {
    label: "männlich",
    value: "m",
  },
  {
    label: "weiblich",
    value: "w",
  },
  {
    label: "divers",
    value: "d",
  },
  {
    label: "nicht definiert",
    value: "x",
  },
];

export const PREFILLED_DOCUMENTS_TITLE =
  "Download für die Signatur durch einen externen Dienstleister";

export const PREFILLED_DOCUMENTS_FORM_ERROR =
  'Bitte füllen Sie alle Pflichtfelder und klicken Sie auf "Speichern und Prüfen", um Ihre Vollmachtsdokumente herunterzuladen.';

export const PREFILLED_DOCUMENTS_SUCCESS_DOWNLOAD = "Download war erfolgreich.";

export const PPOA_DISCONNECTION_ATTRIBUTE = [
  "dauervollmacht",
  "sepaMandat",
  "unterzeichnungsprozess",
  "dauervollmachtId",
];
