import * as React from "react";

const ErrorFilterIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={21.375}
    height={19.5}
    {...props}
  >
    <g data-name="Vorgang enth\xE4lt Fehler">
      <g data-name="Group 4143">
        <g data-name="Group 3283">
          <path
            data-name="Path 391"
            d="m4.5 17.625 1.731-6.259.561-2.242L8.892.75h3.928l1.995 8.374.489 2.053 1.576 6.448Z"
            fill="none"
            stroke="#ec5867"
            strokeMiterlimit={10}
            strokeWidth={1.5}
          />
        </g>
        <path
          data-name="Path 392"
          d="M6.872 9.675h8.06l.537 2.25-9-.141Z"
          fill="#ec5867"
        />
        <path
          data-name="Path 393"
          d="M7.831 5.475h5.712l.519 2.25h-6.75Z"
          fill="#ec5867"
        />
        <path
          data-name="Line 118"
          fill="none"
          stroke="#ec5867"
          strokeMiterlimit={10}
          strokeWidth={1.5}
          d="M0 18.75h21.375"
        />
      </g>
    </g>
  </svg>
);

export default ErrorFilterIcon;
