// Constants
export const COMPONENT_IDS = {
  POA: "poa",
  DASHBOARD: "dashboard",

  APP_DASHBOARD_TENANTS: "appDashboardTenants",
  APP_DASHBOARD_EXPORT_EXCEL: "appDashoardExportExcel",
  APP_DASHBOARD_DLQ: "appDashoardDLQ",
  APP_DASHBOARD_SUPPORT_XML: "appDashoardSupportXML",

  // Sub Tabs Tenants Details Starts
  // 1st Tabs - Profile
  PROFILE: "profile",

  // 2nd Tabs - KBA
  GKS: "gks",
  FORDERUNG: "forderung",
  DATEN_UR_Rechnungsabrechnung: "datenFurRechnungsabrechnung",

  // 3rd Tabs
  BETRIEBE: "betriebe",
  BANK_DETAILS: "bankDetails",

  // 4th Tabs
  MITARBEITER: "mitarbeiter",
  MITARBEITERVERWALTUNG: "mitarbeiterverwaltung",

  // 5th Tabs
  SETTINGS: "settings",
  SETTINGS_WEBHOOK: "settings_webhook",

  // 6th Tabs - No need to take care tenants mapping
  TENANT_GROUP_MAPPING: "tenantGroupMapping",
  // Sub Tabs Tenants Details Ends
};

// Role Permissions For Each Roles
export const RolePermissions = {
  APPROVER: {
    poa: ["create", "read", "update", "delete"],
    dashboard: ["create", "read", "update", "delete"],
    appDashboardTenants: [],
    appDashoardExportExcel: [],
    appDashoardDLQ: [],
    appDashoardSupportXML: [],

    // Sub Tabs
    // 1st Tabs - Profile
    profile: [],

    // 2nd Tabs - KBA
    gks: [],
    forderung: [],
    datenFurRechnungsabrechnung: [],

    // 3rd tabs
    betriebe: [],
    bankDetails: [],

    // 4th tabs
    mitarbeiter: [],
    mitarbeiterverwaltung: [],

    // 5th tabs
    settings: [],
    settings_webhook: [],

    // 6th tabs
    tenantGroupMapping: [],
  },
  SUPPORT: {
    poa: ["read"],
    dashboard: ["read"],
    appDashboardTenants: ["read"],
    appDashoardExportExcel: [],
    appDashoardDLQ: [],
    appDashoardSupportXML: ["read"],

    // Sub Tabs
    // 1st Tabs - Profile
    profile: ["read"],

    // 2nd Tabs - KBA
    gks: [],
    forderung: ["read"],
    datenFurRechnungsabrechnung: ["read"],

    // 3rd tabs
    betriebe: ["read"],
    bankDetails: ["read"],

    // 4th tabs
    mitarbeiter: ["read"],
    mitarbeiterverwaltung: ["read"],

    // 5th tabs
    settings: ["read"],
    settings_webhook: ["read"],

    // 6th tabs
    tenantGroupMapping: ["read"],
  },
  ACCOUNTANT: {
    poa: [],
    dashboard: ["read"],
    appDashboardTenants: ["read"],
    appDashoardExportExcel: ["read"],
    appDashoardDLQ: [],
    appDashoardSupportXML: [],

    // Sub Tabs
    // 1st Tabs - Profile
    profile: ["read"],

    // 2nd Tabs - KBA
    gks: [],
    forderung: ["read"],
    datenFurRechnungsabrechnung: ["read"],

    // 3rd tabs
    betriebe: [],
    bankDetails: [],

    // 4th tabs
    mitarbeiter: [],
    mitarbeiterverwaltung: [],

    // 5th tabs
    settings: [],
    settings_webhook: [],

    // 6th tabs
    tenantGroupMapping: [],
  },
  EXPERT: {
    poa: ["read"],
    dashboard: ["read"],
    appDashboardTenants: ["create", "read"],
    appDashoardExportExcel: ["read"],
    appDashoardDLQ: ["read"],
    appDashoardSupportXML: ["read"],

    // Sub Tabs
    // 1st Tabs - Profile
    profile: ["read", "update"],

    // 2nd Tabs - KBA
    gks: [],
    forderung: ["read"],
    datenFurRechnungsabrechnung: ["read"],

    // 3rd tabs
    betriebe: ["create", "read", "update"],
    bankDetails: ["read"],

    // 4th tabs
    mitarbeiter: ["read", "update"],
    mitarbeiterverwaltung: ["read", "update"],

    // 5th tabs
    settings: ["read", "update"],
    settings_webhook: ["read", "update"],

    // 6th tabs
    tenantGroupMapping: ["read"],
  },
  MDC_ADMIN: {
    poa: [],
    dashboard: [],
    appDashboardTenants: ["create", "read", "update", "delete"],
    appDashoardExportExcel: ["create", "read", "update", "delete"],
    appDashoardDLQ: ["create", "read", "update", "delete"],
    appDashoardSupportXML: ["create", "read", "update", "delete"],

    // Sub Tabs
    // 1st Tabs - Profile
    profile: ["create", "read", "update", "delete"],

    // 2nd Tabs - KBA
    gks: ["create", "read", "update", "delete"],
    forderung: ["create", "read", "update", "delete"],
    datenFurRechnungsabrechnung: ["create", "read", "update", "delete"],

    // 3rd tabs
    betriebe: ["create", "read", "update", "delete"],
    bankDetails: ["create", "read", "update", "delete"],

    // 4th tabs
    mitarbeiter: ["create", "read", "update", "delete"],
    mitarbeiterverwaltung: ["create", "read", "update", "delete"],

    // 5th tabs
    settings: ["create", "read", "update", "delete"],
    settings_webhook: ["create", "read", "update", "delete"],

    // 6th tabs
    tenantGroupMapping: ["create", "read", "update", "delete"],
  },
  ADMIN: {
    poa: ["create", "read", "update", "delete"],
    dashboard: ["read"],
    appDashboardTenants: [],
    appDashoardExportExcel: [],
    appDashoardDLQ: [],
    appDashoardSupportXML: [],

    // Sub Tabs
    // 1st Tabs - Profile
    profile: ["create", "read", "update", "delete"],

    // 2nd Tabs - KBA
    gks: ["create", "read", "update", "delete"],
    forderung: ["create", "read", "update", "delete"],
    datenFurRechnungsabrechnung: ["create", "read", "update", "delete"],

    // 3rd tabs
    betriebe: ["create", "read", "update", "delete"],
    bankDetails: ["create", "read", "update", "delete"],

    // 4th tabs
    mitarbeiter: ["create", "read", "update", "delete"],
    mitarbeiterverwaltung: ["create", "read", "update", "delete"],

    // 5th tabs
    settings: ["read"],
    settings_webhook: ["read", "delete", "update"],

    // 6th tabs
    tenantGroupMapping: ["create", "read", "update", "delete"],
  },
  API_USER: {
    poa: ["create", "read", "update", "delete"],
    dashboard: ["read"],
    appDashboardTenants: [],
    appDashoardExportExcel: [],
    appDashoardDLQ: [],
    appDashoardSupportXML: [],

    // Sub Tabs
    // 1st Tabs - Profile
    profile: ["create", "read", "update", "delete"],

    // 2nd Tabs - KBA
    gks: ["create", "read", "update", "delete"],
    forderung: ["create", "read", "update", "delete"],
    datenFurRechnungsabrechnung: ["create", "read", "update", "delete"],

    // 3rd tabs
    betriebe: ["create", "read", "update", "delete"],
    bankDetails: ["create", "read", "update", "delete"],

    // 4th tabs
    mitarbeiter: ["create", "read", "update", "delete"],
    mitarbeiterverwaltung: ["create", "read", "update", "delete"],

    // 5th tabs
    settings: ["read"],
    settings_webhook: [],

    // 6th tabs
    tenantGroupMapping: [],
  },
};

export default RolePermissions;
