import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// Components
import MDCLabel from "../core/MDCLabel";
import MDCButton from "../core/MDCButton";
import MDCStepper from "../core/MDCStepper";
import MDCLoader from "../core/MDCLoader";
import DocumentUpload from "../shared/DocumentUpload";
import ErrorText from "../shared/ErrorText";
import MDCRadioButton from "../core/MDCRadioButton";

// Button Icons
import RefreshIcon from "../iconComponents/RefreshIcon";

// Constants
import {
  VollmachtFormConstants,
  documentNames,
  yesORnoOptions,
} from "../../constants/ManualRequestFormConstants";

// Material UI
import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";
import theme from "../../themes/mdc-theme";

// Assets
import verimiImage from "../../assets/images/verimi-image.PNG";

// Actions
import { VollmachtsDatenBankDataActions } from "../../store/actions/userProfile/vollmachtsDatenBankAction";

const useStyles = makeStyles((theme) => ({
  containerCSS: {
    padding: "1rem",
    backgroundColor: theme.palette.MDCColors.white,
  },
  vollmachtStep: {
    display: "flex",
    flexDirection: "column !important",
    marginBottom: "3rem !important",
  },
  width75: {
    width: "75%",
  },
  QRLoader: {
    marginTop: "1.5rem",
    height: "87px",
    width: "18em",
    display: "flex",
    justifyContent: "center",
  },
  verimiImage: {
    backgroundRepeat: "no-repeat",
    backgroundSize: "100%",
    backgroundImage: `url(${verimiImage})`,
    height: "300px",
    backgroundSize: "90%",
    backgroundPosition: "center",
  },
  vollmachtStep3rd: {
    textAlign: "end",
    alignItems: "flex-end",
  },
  columnTitle: {
    fontSize: "1rem",
    fontWeight: "bold",
    fontFamily: theme.palette.typography.fontFamily,
    marginBottom: "1.25rem",
  },
}));

const POARecordQES = (props) => {
  const poaId = props?.location?.state?.poaId;

  const vollmachtsdatenbankPOADetails =
    props?.location?.state?.vollmachtsdatenbankPOADetails;

  const {
    containerCSS,
    vollmachtStep,
    width75,
    QRLoader,
    verimiImage,
    vollmachtStep3rd,
    columnTitle,
  } = useStyles();

  const history = useHistory();

  const dispatch = useDispatch();

  const vollmachtsdatenbankPOAForQESStatus = useSelector(
    (state) => state?.vollmachtDatenBankReducer?.qesStatus
  );

  const qesSigningStatus = !vollmachtsdatenbankPOAForQESStatus?.completedStage
    ? "ja"
    : "nein";

  const [blinkingValue, setBlinkingValue] = useState(null);
  const [loadingQRCode, setloadingQRCode] = useState(false);
  const [loading, setLoading] = useState(false);

  const [documentsUpload, setDocumentsUpload] = useState({
    dauervollmacht: vollmachtsdatenbankPOADetails?.dauervollmacht || "",
    sepaMandat: vollmachtsdatenbankPOADetails?.sepaMandat || "",
  });

  const [qesType, setqesType] = useState(qesSigningStatus);

  const prevPropsRef = useRef(null);

  let signingProcessTimerRef = useRef(null); // Ref to store the signing Process timer

  const getQRCodeURL = (vollmachtsdatenbankPOAQRCode) => {
    if (vollmachtsdatenbankPOAQRCode?.size > 0) {
      return URL.createObjectURL(vollmachtsdatenbankPOAQRCode);
    }
  };

  const vollmachtsdatenbankPOAQRCode = useSelector(
    (state) => state?.vollmachtDatenBankReducer?.QRCode
  );

  const vollmachtsdatenbankPOAForQESUpdate = useSelector(
    (state) =>
      state?.vollmachtDatenBankReducer?.vollmachtsdatenbankPOAForQESUpdate
  );

  const updateErrorMessage =
    vollmachtsdatenbankPOAForQESUpdate?.validationMessages;

  const setBlinkingIconOrRedirectToVollmachtBankList = (completedStage) => {
    if (completedStage === "" || completedStage === "started") {
      setBlinkingValue(1);
    } else if (completedStage === "ident" || completedStage === "verify") {
      setBlinkingValue(2);
    } else if (completedStage === "sign") {
      clearInterval(signingProcessTimerRef?.current);
      setBlinkingValue(3);
      history.push("/vollmachtsdatenbank");
    }
  };

  useEffect(() => {
    const isNonQESProcess = qesType === "nein";

    if (
      isNonQESProcess &&
      (vollmachtsdatenbankPOAQRCode === undefined ||
        vollmachtsdatenbankPOAQRCode === null)
    ) {
      setloadingQRCode(true);
      dispatch(
        VollmachtsDatenBankDataActions.getVollmachtBankPOAQRCode(poaId)
      ).then(() => setloadingQRCode(false));
    }

    prevPropsRef.current =
      vollmachtsdatenbankPOAForQESStatus !== undefined &&
      vollmachtsdatenbankPOAForQESStatus.completedStage;

    if (
      prevPropsRef?.current === undefined ||
      ((prevPropsRef?.current?.completedStage ?? null) !==
        vollmachtsdatenbankPOAForQESStatus?.completedStage &&
        blinkingValue !== 3)
    ) {
      const completedStage = vollmachtsdatenbankPOAForQESStatus?.completedStage;

      if (
        prevPropsRef?.current?.completedStage !==
        vollmachtsdatenbankPOAForQESStatus?.completedStage
      ) {
        setBlinkingIconOrRedirectToVollmachtBankList(completedStage);
      } else if (
        prevPropsRef?.current?.completedStage ===
        vollmachtsdatenbankPOAForQESStatus?.completedStage
      ) {
        setBlinkingIconOrRedirectToVollmachtBankList(completedStage);
      }

      if (isNonQESProcess) {
        signingProcessTimerRef.current = setInterval(() => {
          dispatch(
            VollmachtsDatenBankDataActions.getVollmachtBankSigningStatus(poaId)
          );
        }, 10000);

        return () => {
          clearInterval(signingProcessTimerRef?.current);
        };
      }
    }

    return () => {
      if (qesType === "ja" && signingProcessTimerRef?.current) {
        clearInterval(signingProcessTimerRef?.current);
      }
    };
  }, [vollmachtsdatenbankPOAForQESStatus, qesType, dispatch]);

  const checkForFileUploadError = () => {
    //If Any/All of the 2 documents contains the error then it will be displayed in Error text component under the document upload section
    let fileError = "";
    let dataArray = Object.keys(documentNames);

    for (let i = 0; i < dataArray.length; i++) {
      if (documentsUpload[dataArray[i]].error) {
        fileError = documentsUpload[dataArray[i]].error;
        break;
      }
    }
    return fileError;
  };
  const handleDocumentUpload = (event) => {
    const documentValue = { ...documentsUpload };
    documentValue[event.target.name] = event.target.value;
    setDocumentsUpload(documentValue);
  };

  const handleQESTypeChange = (event) => {
    setqesType(event.target.value);
  };

  const getHeader = (
    <Grid container justifyContent={"space-between"} alignItems={"center"}>
      <Grid item xs={7} color={theme.palette.MDCColors.black}>
        <h3>Dauervollmacht hinzufügen </h3>
      </Grid>
      <Grid item />
    </Grid>
  );

  const QESProcessView = (
    <>
      {/* Blinker stepper */}
      <Grid marginBottom={"1rem"} width={"100%"}>
        <MDCStepper step={blinkingValue} />
      </Grid>

      <Grid container>
        <Grid item xs={4} className={vollmachtStep} id="step-1-container">
          <>
            <Grid marginBottom="2.5rem">
              <MDCLabel label="Scan" variant="h6" />
              <p className={width75}>
                {VollmachtFormConstants.STEP_ONE_CONTENT}
              </p>
            </Grid>
            <div>
              <MDCButton
                variant="outlined"
                label={"Neuen QR Code generieren"}
                disabled={false}
                onClick={() => {
                  setloadingQRCode(true);
                  dispatch(
                    VollmachtsDatenBankDataActions.regenerateVollmachtBankPOAQRCode(
                      poaId
                    )
                  ).then(() => {
                    setloadingQRCode(false);
                  });
                }}
                endIcon={<RefreshIcon />}
              />
            </div>
            <div>
              {loadingQRCode ? (
                <div className={QRLoader}>
                  <MDCLoader />
                </div>
              ) : (
                <>
                  {!loadingQRCode &&
                    vollmachtsdatenbankPOAQRCode !== undefined &&
                    vollmachtsdatenbankPOAQRCode?.size > 0 && (
                      <img
                        alt="Vollmacht QR Code"
                        src={getQRCodeURL(vollmachtsdatenbankPOAQRCode)}
                        width="50%"
                      />
                    )}
                </>
              )}
            </div>
          </>
        </Grid>

        <Grid item xs={4} className={vollmachtStep} id="step-2-container">
          <>
            <Grid textAlign="center" marginBottom="1.5rem">
              <MDCLabel label="Vollmachterstellung via Verimi" variant="h6" />
              <p>{VollmachtFormConstants.STEP_TWO_CONTENT}</p>
            </Grid>
            <div className={verimiImage} />
          </>
        </Grid>

        <Grid
          item
          xs={4}
          className={`${vollmachtStep} ${vollmachtStep3rd}`}
          id="step-3-container"
        >
          <Grid width="18rem" marginBottom="2.5rem">
            <MDCLabel label="Vollmacht empfangen" variant="h6" />
            <p>{VollmachtFormConstants.STEP_THREE_CONTENT}</p>
          </Grid>
        </Grid>
      </Grid>
    </>
  );

  const documentUploadView = (
    <Grid container>
      <Grid width="33%">
        <label className={columnTitle}>Dokument-Upload (nur PDF)</label>
        {Object.keys(documentNames).map((docName, index) => {
          return (
            <DocumentUpload
              key={index}
              documentName={docName}
              uploadedFileData={documentsUpload[docName]}
              handleChange={(e) => handleDocumentUpload(e)}
              error={updateErrorMessage && updateErrorMessage[docName]}
              isShowUploadedLabel
            />
          );
        })}
        <ErrorText id="file-upload-error" error={checkForFileUploadError()} />
      </Grid>
    </Grid>
  );

  return (
    <Grid container className={containerCSS}>
      {/* Header */}
      {getHeader}

      {/* Body */}
      {loading ? (
        <Grid container margin={"10rem"} justifyContent={"center"}>
          <MDCLoader />
        </Grid>
      ) : (
        <Grid container>
          <Grid item xs={4} marginY={"1rem"}>
            <MDCLabel label="Haben Sie bereits signierte Dokumente vorliegen und möchten diese hochladen?" />
            <Grid marginY={".5rem"}>
              <MDCRadioButton
                name="qesType"
                selected={qesType}
                options={yesORnoOptions}
                disabled={false}
                onChange={(e) => handleQESTypeChange(e)}
                row
              />
            </Grid>
          </Grid>
          {qesType === "nein" ? QESProcessView : documentUploadView}
        </Grid>
      )}

      <Grid container mt={"2.5rem"} pb={3} justifyContent="space-between">
        <Grid item>
          <MDCButton
            onClick={() => history.push("/vollmachtsdatenbank")}
            variant="outlined"
            label="Schließen"
          />
        </Grid>
        <Grid item>
          {qesType !== "nein" && (
            <MDCButton
              onClick={() => {
                setLoading(true);
                dispatch(
                  VollmachtsDatenBankDataActions.updateVollmachtBankPOAForQES(
                    documentsUpload,
                    poaId
                  )
                ).then((resp) => {
                  if (resp?.data) {
                    setLoading(false);
                  }
                });
              }}
              variant="contained"
              label={"In Datenbank speichern"}
            />
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default POARecordQES;
