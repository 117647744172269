import React from "react";
import { connect } from "react-redux";
import { Buffer } from "buffer";

//Core Components
import MDCButton from "../core/MDCButton";
import MDCBrowseFile from "../core/MDCBrowseFile";
import MDCLabel from "../core/MDCLabel";
import DeleteConfirmationDialog from "../shared/DeleteConfirmationDialog";
import ErrorText from "./ErrorText";

// Constants
import {
  documentNames,
  PPOAdocumentNames,
} from "../../constants/ManualRequestFormConstants";

// Helpers
import { getFileExtension } from "../../helpers/fileExtension";
import { getBase64String } from "../../helpers/utils";

// Material UI
import Grid from "@mui/material/Grid";
import { withStyles } from "@mui/styles";

// Icon Components
import FileUploadIcon from "../../components/iconComponents/FileUploadIcon";
import DiscardIcon from "../../components/iconComponents/DiscardIcon";
import DownloadFileIcon from "../../components/iconComponents/DownloadFileIcon";

const styles = () => ({
  fileNameCSS: {
    marginLeft: "1rem",
  },
  fileGridCSS: {
    cursor: "pointer",
  },
});

class DocumentUpload extends React.Component {
  constructor(props) {
    super(props);
    this.inputElement = React.createRef();

    // Set the initial values
    this.state = {
      openLoschenPopup: false,
      ALLOWED_EXTENSIONS: ["pdf"],
    };
  }
  handleFiles = (event) => {
    const fileData = event.target?.files[0];
    const fileName = event.target?.files[0]?.name;
    if (
      this.state.ALLOWED_EXTENSIONS.indexOf(getFileExtension(fileName)) > -1 && //Checking PDF extension
      fileData
    ) {
      //Converting Blob data in base 64 encoded string to send it as a json object in Axios API call
      getBase64String(fileData).then((resp) => {
        const base64File = resp.replace("data:application/pdf;base64,", ""); // removing base64 header from encoded string

        const fileEventObj = {
          target: {
            name: this.props?.documentName,
            value: base64File,
          },
        }; //Setting same event attribute like others to use it in handleChange of Manual Request for halter Request form Data

        this.props.handleChange(fileEventObj);
      });
    }
  };

  downloadDocumentFile = () => {
    const base64DecodedData = Buffer.from(
      this.props?.uploadedFileData,
      "base64"
    );
    const documentBlobData = new Blob([base64DecodedData], {
      type: "application/octet-stream",
    });
    const documentBlobDataURL = URL.createObjectURL(documentBlobData);
    const link = document.createElement("a");
    link.href = documentBlobDataURL;
    link.setAttribute(
      "download",
      `${documentNames[this.props?.documentName].fileName}.pdf`
    );
    document.body.appendChild(link);
    link.click();
  };
  deleteUploadedFile = () => {
    this.props.handleChange({
      target: {
        name: this.props?.documentName,
        value: "",
      },
    });
    this.setState({ openLoschenPopup: false });
  };
  render() {
    const availableDocumentNames = this.props?.isPPOA
      ? PPOAdocumentNames
      : documentNames;

    return (
      <>
        {this.props.uploadedFileData ? (
          <Grid
            container
            marginY=".5rem"
            className={this.props?.classes?.fileGridCSS}
          >
            <Grid
              item
              xs={7}
              display="flex"
              alignItems="center"
              marginY={".5rem"}
            >
              <Grid onClick={this.downloadDocumentFile}>
                <DownloadFileIcon color="#00C4DE" />
              </Grid>
              <Grid className={this.props?.classes?.fileNameCSS}>
                <MDCLabel
                  label={`${
                    availableDocumentNames[this.props?.documentName].fileName
                  } ${this.props?.isShowUploadedLabel ? "hochgeladen" : ""}`}
                  isReadOnly
                />
              </Grid>
            </Grid>
            <Grid item xs={5} textAlign="end">
              {!this.props.isDetailDialog && (
                <MDCButton
                  variant="outlined"
                  label={"Löschen"}
                  onClick={() => this.setState({ openLoschenPopup: true })}
                  endIcon={<DiscardIcon color="#00C4DE" />}
                />
              )}
            </Grid>
            <ErrorText
              id={this.props?.documentName}
              error={this.props?.error}
            />
          </Grid>
        ) : (
          <Grid container flexDirection={"column"} marginY=".5rem">
            {!this.props?.isDetailDialog && (
              <>
                <MDCButton
                  variant="outlined"
                  documentName={this.props.documentName}
                  label={`${
                    availableDocumentNames[this.props?.documentName].fileName
                  } hochladen`}
                  endIcon={<FileUploadIcon />}
                  onClick={() => this.inputElement.click()}
                />
                <MDCBrowseFile
                  acceptFileFormats=".pdf"
                  inputRef={(input) => (this.inputElement = input)}
                  onChange={(e) => this.handleFiles(e)}
                />
              </>
            )}
            <ErrorText
              id={this.props?.documentName}
              error={this.props?.error}
            />
          </Grid>
        )}
        <DeleteConfirmationDialog
          show={this.state.openLoschenPopup}
          handlePopupClose={() => this.setState({ openLoschenPopup: false })}
          handlePopupSubmit={this.deleteUploadedFile}
          headerTitle={"Upload löschen"}
          confirmationMessage={
            "Sind Sie sicher, dass Sie dieses Dokument löschen möchten?"
          }
        />
      </>
    );
  }
}
function mapStateToProps() {
  return {};
}

const mapDispatchToProps = () => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(DocumentUpload));
