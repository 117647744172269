import * as React from "react";
const CrossIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={14.849}
    height={14.849}
    {...props}
  >
    <g
      fill="none"
      stroke="#5c5b5b"
      strokeLinecap="round"
      strokeWidth={2}
      data-name="Group 4970"
    >
      <path d="m13.435 1.414-12.02 12.02" data-name="Line 198" />
      <path d="m1.414 1.414 12.021 12.021" data-name="Line 199" />
    </g>
  </svg>
);
export default CrossIcon;
