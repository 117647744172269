import React from "react";
import { Drawer } from "@mui/material";

const MDCDrawer = (props) => {
  const { open, onClose, anchor, children } = props;
  return (
    <>
      <Drawer open={open} onClose={onClose} anchor={anchor ? anchor : "right"}>
        {children}
      </Drawer>
    </>
  );
};

export default MDCDrawer;
