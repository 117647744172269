import { makeStyles } from "@mui/styles";
import React from "react";

import {
  alertCSS,
  alertInfoCSS,
  alertErrorCSS,
  alertSuccessCSS,
  alertDuplicateCSS,
  alertWithInfoIconCSS,
} from "../shared/AlertCSS";
import { Grid } from "@mui/material";
import MDCButton from "./MDCButton";

const useStyles = makeStyles(() => ({
  alert: alertCSS,
  alertInfo: alertInfoCSS,
  alertError: alertErrorCSS,
  alertSuccess: alertSuccessCSS,
  alertDuplicate: alertDuplicateCSS,
  alertWithInfoIcon: alertWithInfoIconCSS,
}));

export default function MDCBannerDetailView({
  children,
  error,
  success,
  isDuplicate,
  isInfoIcon,
  handleErrorMessagesDetailButton,
}) {
  const {
    alert,
    alertInfo,
    alertSuccess,
    alertError,
    alertDuplicate,
    alertWithInfoIcon,
  } = useStyles();

  let MDCStyles = `${alert}`;

  if (error) {
    MDCStyles = `${MDCStyles} ${alertError}`;
  } else if (success) {
    MDCStyles = `${MDCStyles} ${alertSuccess}`;
  } else if (isDuplicate) {
    MDCStyles = `${MDCStyles} ${alertDuplicate}`;
  } else if (isInfoIcon) {
    MDCStyles = `${MDCStyles} ${alertWithInfoIcon}`;
  } else {
    MDCStyles = `${MDCStyles} ${alertInfo}`;
  }

  return (
    <div className={`${MDCStyles}`}>
      <Grid
        container
        marginLeft={"0.5rem"}
        marginY={"0.5rem"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Grid item xs={10}>
          {children}
        </Grid>
        <Grid item xs={1.5}>
          <MDCButton
            variant="outlined"
            label={"Details anzeigen"}
            onClick={() => {
              handleErrorMessagesDetailButton();
            }}
          />
        </Grid>
      </Grid>
    </div>
  );
}
