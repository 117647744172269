import { config } from "./config";
import axios from "axios";
import { history } from "../../helpers/history";

const checkEmployeeID = () => {
  const url = `${config.apiUrl}/users/me`;
  return axios
    .get(url)
    .then((resp) => {
      return resp.data;
    })
    .catch((error) => {
      return error;
    });
};

async function getUserDetails() {
  try {
    const headers = {
      "Access-Control-Allow-Origin": "*",
      Accept: "application/json",
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
    };
    const resp = await axios.get(`${config.apiUrl}/oauth2/user-info`, headers);

    localStorage.setItem("user", JSON.stringify(resp?.data));
    localStorage.setItem(
      "activeUser",
      JSON.stringify({
        groupID: resp?.data?.pGroupId,
        value: resp?.data?.pTenantAlias,
      })
    );

    return resp?.data;
  } catch (error) {
    history.push("/login");
    localStorage.clear();
    return error;
  }
}

async function logout() {
  const url = `${config.apiUrl}/users/kclogout`;
  try {
    window.open(url, "_self");
    // return resp;
  } catch (error) {
    return error;
  }
}

function verimiLogin() {
  // localStorage.setItem("user", JSON.stringify(userDetails));
  // getAndSetUserDetails();
  // var bodyFormData = new FormData();
  // bodyFormData.append("handshakeToken", accessToken);
  // const requestOptions = {
  //   method: "POST",
  //   headers: { "Content-Type": "multipart/form-data" },
  //   data: bodyFormData,
  // };
  // return axios(`${config.apiUrl}/oauth2/handshake`, requestOptions)
  //   .then(() => {
  //     return getAndSetUserDetails();
  //   })
  //   .catch((error) => {
  //     return error;
  //   });
}

const saveEmployeeID = (employeeId) => {
  const url = `${config.apiUrl}/users/me`;
  return axios
    .patch(url, employeeId)
    .then((resp) => {
      if (resp?.status === 200) {
        let user = JSON.parse(localStorage.getItem("user"));
        user.employeeId = employeeId.employeeId;
        localStorage.setItem("user", JSON.stringify(user));
      }
      return resp;
    })
    .catch((error) => {
      return error;
    });
};

function getCompanyDetails(aliasName) {
  const url = `${config.apiUrl}/tcs/${aliasName}/summary`;
  return axios
    .get(url)
    .then((resp) => {
      return resp;
    })
    .catch((error) => {
      return error;
    });
}

function getProductVersion() {
  const url = `${config.apiUrl}/application/version`;
  return axios
    .get(url)
    .then((resp) => {
      return resp;
    })
    .catch((error) => {
      return error;
    });
}

function getExcelTempalte() {
  const url = `${config.apiUrl}/vorgangs/csv/template`;

  return axios
    .get(url, { responseType: "blob" })
    .then((resp) => {
      return resp;
    })
    .catch((error) => {
      return error;
    });
}

function getTempaltePDF(docType) {
  const url = `${config.apiUrl}/vorgangs/templates/${docType}`;
  return axios
    .get(url, { responseType: "blob" })
    .then((resp) => {
      return resp;
    })
    .catch((error) => {
      return error;
    });
}

// Verimi Register
function verimiRegister(data) {
  const { email, confirmEmail, privacyPolicy, termAndConditions } = data;
  const requestJson = {
    emailAddress: email,
    confirmEmail: confirmEmail,
    useOfDataFlag: privacyPolicy,
    termsAndConditionsFlag: termAndConditions,
  };

  return axios
    .post(`${config.apiUrl}/tcs/registration`, requestJson)
    .then((resp) => {
      return resp;
    })
    .catch((error) => {
      return error;
    });
}

function tenantContractIdRegistration(tenantIds) {
  const { betriebsnummer, vertragsId, tenantVertragsCheckKey } = tenantIds;

  const url = `${config.apiUrl}/tcs/registration/${tenantVertragsCheckKey}/vertrags-details`;
  return axios
    .put(url, { betriebsnummer, vertragsId })
    .then((resp) => {
      return resp;
    })
    .catch((error) => {
      return error;
    });
}

export const userService = {
  logout,
  verimiLogin,
  getUserDetails,
  // startRefreshTokenTimer,
  checkEmployeeID,
  saveEmployeeID,
  getCompanyDetails,
  verimiRegister,
  getProductVersion,
  getExcelTempalte,
  getTempaltePDF,
  tenantContractIdRegistration,
};
