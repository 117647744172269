import forEach from "lodash/forEach";

import result from "lodash/result";
import find from "lodash/find";

// Constants
import {
  KENNZEICHEN_OPTIONS_0,
  KENNZEICHEN_OPTIONS_1,
} from "../constants/ManualRequestFormConstants";

const formTag = [
  "Vorgangsdaten",
  "Fahrzuegidentifizierung",
  "Kennzeichen",
  "Fahrzeughalter",
  "Vollmacht",
];

const formConstants = {
  individualPerson: "naturliche",
  companyPerson: "juristische",
  Krankenwagen: "K",
  Linienbus: "L",
  Mietfahrzeug: "M",
  Selbstfahrermietfahrzeug: "S",
  Taxi: "T",
  "sonstige Verwendung": "X",
  jährlich: "jährlich",
  Halbjaehrlich: "halbjaehrlich",
  Vierteljaehrlich: "vierteljaehrlich",
  Steuerfrei: "steuerfrei",
};

const splittedLicenseFields = ["kennzeichenF", "kennzeichenM", "kennzeichenL"];

const splitedFieldsforLicensePlate = [
  "kennzeichenF",
  "kennzeichenM",
  "kennzeichenL",
  "pin",
];

const kennzeichenReservierungRadioLabel = [
  "Nächstes freies Kennzeichen",
  "Reserviertes Wunschkennzeichen",
];

const formfieldData = {
  data: [
    {
      id: "Vorgangsdaten",
      value: [
        {
          id: "NameDesHalters",
          value: [
            {
              id: "personenform",
            },
          ],
        },
        {
          id: "Vorgangsinformationen",
          value: [
            {
              id: "vorgangsart",
            },
            {
              id: "datumWirksamkeit",
            },
            {
              id: "status",
            },
          ],
        },
        {
          id: "Kundeninformationen",
          value: [
            {
              id: "großkundenantrag",
            },
            // gosskundenID
            {
              id: "kundennummer",
            },
          ],
        },
        {
          id: "Vertragsdaten",

          value: [
            // kaufvertragsnummer
            {
              id: "referenzID_24",
            },
            // operation Id assigned to that user
            {
              id: "betriebsnummern",
            },
            // interneVorgangsnummer
            {
              id: "referenzID_23",
            },
            // Leasingvertragsnummer
            {
              id: "referenzID_25",
            },
            // Antragsnummer
            {
              id: "antragsnummer",
            },
          ],
        },
        {
          id: "Zulassungsinformation",
          value: [
            // Nummer der Zulassungsstelle (STBA)
            {
              id: "behoerdeKreisschluessel",
            },
            // Wunschdatum der Außerbetriebsetzung
            {
              id: "DatumErstzulassung",
            },
            {
              id: "erstezulassung",
            },
          ],
        },
      ],
    },
    {
      id: "Fahrzuegidentifizierung",
      value: [
        {
          id: "Gestellnummern",
          value: [
            // Fahrzeugidentifizierungsnummer (FIN)
            {
              id: "Fahrzeugidentifizierungsnummer",
            },
            // finPrufziffer
            {
              id: "PruefzifferFahrzeugidentifizierungsnummer",
            },
            // Zulassungsbehörde
            {
              id: "SchluesselHersteller",
            },
            // typschlusselnummer
            {
              id: "SchluesselTyp",
            },
          ],
        },
        {
          id: "Verwertung",
          value: [
            {
              id: "merkmalverwertungsnachweis",
              value: "1",
            },
            {
              id: "StaatVerwertung",
              value: "",
            },
            {
              id: "BetriebsnummerDemontagebetrieb",
              value: "",
            },
            {
              id: "DatumAusstellung",
              value: "",
            },
          ],
        },
        {
          id: "ZulassungsBescheinigung",
          value: [
            // zulassungsbescheinigungTeilIICode
            {
              id: "NummerZulBeschTeil2",
            },
            // sicherheitscode_teil2
            {
              id: "sicherheitscode_teil2",
            },
            // Not using - Zb1_Nummer
            {
              id: "NummerZulBeschTeil1",
            },
            // zb1_sicherheitscode
            {
              id: "sicherheitscode_teil1",
            },
          ],
        },

        {
          id: "Fahrzeugprufung",
          value: [
            // elektronische Versicherungsbestätigungsnummer (eVB)
            {
              id: "Versicherungsbestaetigungsnummer",
            },
          ],
        },
        {
          id: "Verwendung",
          value: [
            // Fahrzeug
            {
              id: "VerwendungFahrzeug",
            },
          ],
        },
        {
          id: "Hauptuntersuchung",
          value: [
            {
              id: "NaechsteHauptuntersuchung",
            },
            {
              id: "artDerUntersuchung",
            },
          ],
        },
      ],
    },

    {
      id: "Kennzeichen",
      value: [
        {
          id: "Kennzeichenreservierung",
          value: [
            {
              id: "naechstesKennzeichen",
            },
            {
              id: "reservieterKundenkreis",
            },
            {
              id: "pin",
            },
            {
              id: "Kennzeichen",
            },
            {
              id: "reservietesKennzeichenBis",
            },
            {
              id: "MerkmalReservierungKennzeichen",
            },
          ],
        },
        {
          id: "KennzeichenaanzahlUndArt",
          // eKennezeichen
          value: [
            {
              id: "MerkmalElektrokennzeichen",
            },
            // Two Wheeler registration
            {
              id: "ZweiraderKennzeichen",
            },
          ],
        },
        {
          id: "Kennzeicheninformationen",
          value: [
            // Kennzeichensicherheitscode vorne
            {
              id: "sicherheitscode_vorn",
            },
            // Kennzeichensicherheitscode hinten
            {
              id: "sicherheitscode_hinten",
            },
            // old number plate
            {
              id: "OldKennzeichen",
            },
            // Kennzeichensicherheitscode hinten
            {
              id: "UmmeldungOldKennzeichenRetained",
            },
          ],
        },
      ],
    },
    {
      id: "Fahrzeughalter",
      value: [
        {
          id: "NameDesHalters",
          value: [
            {
              id: "SchluesselBeruf",
            },
            {
              id: "halterAddress",
            },
            {
              id: "entspricht",
            },
            // Name
            {
              id: "auskunft_NatuerlichePerson_FamiliennameNachname",
            },
            {
              id: "vorname",
            },
            // J Name
            {
              id: "NameJuristischePerson",
            },
            {
              id: "geburtsort",
            },
            {
              id: "geburtsnameNachname",
            },
            {
              id: "geburtstag",
            },
            {
              id: "geschlecht",
            },
          ],
        },
        {
          id: "Anschrift",
          value: [
            {
              id: "Strasse",
            },
            {
              id: "Wohnort",
            },
            {
              id: "Hausnummer",
            },
            {
              id: "Postleitzahl",
            },
          ],
        },
        {
          id: "Steuermandat",
          value: [
            {
              id: "gosskundenIDVorhanden", // Missing
            },
            {
              id: "steuerzahlweise",
            },
            {
              id: "steuerbefreiung",
            },
          ],
        },
        {
          id: "Bankverbindung",
          value: [
            {
              id: "bankverbindung",
            },
            {
              id: "kontoinhaber",
            },
            {
              id: "nameBankverbindung",
            },
            {
              id: "iban",
            },

            {
              id: "bic",
            },
          ],
        },
        {
          id: "ZollgroßKundennummer",
          value: [
            {
              id: "Vorhanden",
            },
            {
              id: "kundennummerdeshalters",
            },
          ],
        },
        {
          id: "IdentDokument",
          value: [
            {
              id: "dauervollmacht",
            },
            {
              id: "sepaMandat",
            },
          ],
        },
        {
          id: "ZustellungZbt1",
          value: [
            { id: "zbt1ZustellungType" },
            { id: "zbt1AddressType" },
            { id: "zbt1IndividualAddresseType" },
            { id: "zbt1PersonVorname" },
            { id: "zbt1PersonNachname" },
            { id: "zbt1Unternehmen" },
            { id: "zbt1Strasse" },
            { id: "zbt1Stadt" },
            { id: "zbt1Hausnummer" },
            { id: "zbt1Postleitzahl" },
          ],
        },
        {
          id: "ZustellungZbt2",
          value: [
            { id: "zbt2ZustellungType" },
            { id: "zbt2AddressType" },
            { id: "zbt2IndividualAddresseType" },
            { id: "zbt2PersonVorname" },
            { id: "zbt2PersonNachname" },
            { id: "zbt2Unternehmen" },
            { id: "zbt2Strasse" },
            { id: "zbt2Stadt" },
            { id: "zbt2Hausnummer" },
            { id: "zbt2Postleitzahl" },
          ],
        },
      ],
    },

    {
      id: "Vollmacht",
      value: [
        {
          id: "IdentDokument",
          value: [
            {
              id: "dauervollmacht",
            },
            {
              id: "sepaMandat",
            },
            { id: "gesetztlicherVertreter" },
            { id: "vollmachtsprozess" },
            { id: "unterzeichnungsprozess" },
            { id: "dauervollmachtId" },
          ],
        },
      ],
    },
  ],
};

const fieldsNotRequiredForDuplicateCount = [
  "naechstesKennzeichen",
  "reservieterKundenkreis",
  "Kennzeichen",
];

const fieldsRequiredForRadioButtons = [
  "naechstesKennzeichen",
  "reservieterKundenkreis",
];

const antragDefaultForm = {
  personenform: {
    value: formConstants.companyPerson,
    error: "",
  },
  hasError: false,
  hasDuplicate: false,
  formDuplicate: 0, // number of duplicate at form
  vorgangsart: { value: "", error: "" },
  status: { value: "", error: "" },
  datumWirksamkeit: { value: "", error: "" },
  isVorgangsnummer: { value: "ja", error: "", display: true },
  referenzID_23: {
    value: "",
    error: "",
    disabled: false,
  },
  betriebsnummern: {
    value: "",
    error: "",
  },
  referenzID_24: { value: "", error: "" },
  // leasingvertragsnummer
  referenzID_25: { value: "", error: "" },
  antragsnummer: { value: "", error: "" }, //Antragsnummer field
  // Nummer der Zulassungsstelle (STBA)
  behoerdeKreisschluessel: { value: "", error: "" },
  DatumErstzulassung: { value: "", error: "" },
  erstezulassung: { value: "", error: "" },
  großkundenantrag: { value: "ja", error: "" }, //Missing
  kundennummer: { value: "", error: "" },
};

const fahrzeugDefaultForm = {
  hasError: false,
  hasDuplicate: false,
  formDuplicate: 0, // number of duplicate at form
  Fahrzeugidentifizierungsnummer: {
    value: "",
    error: "",
  },
  PruefzifferFahrzeugidentifizierungsnummer: { value: "", error: "" },
  SchluesselHersteller: { value: "", error: "" },
  SchluesselTyp: { value: "", error: "" },
  /*eslint camelcase: ["error", {properties: "never"}]*/
  sicherheitscode_teil1: { value: "", error: "" },
  /*eslint camelcase: ["error", {properties: "never"}]*/
  NummerZulBeschTeil1: { value: "", error: "" },
  NummerZulBeschTeil2: { value: "", error: "" },
  sicherheitscode_teil2: { value: "", error: "" },
  Versicherungsbestaetigungsnummer: { value: "", error: "" },
  VerwendungFahrzeug: { value: "", error: "" },
  merkmalverwertungsnachweis: { value: "1", error: "" },
  StaatVerwertung: { value: "", error: "" },
  BetriebsnummerDemontagebetrieb: { value: "", error: "" },
  DatumAusstellung: { value: "", error: "" },
  NaechsteHauptuntersuchung: { value: "", error: "" },
  artDerUntersuchung: { value: "", error: "" },
};

const kennzeichenDefaultForm = {
  hasError: false,
  hasDuplicate: false,
  formDuplicate: 0, // number of duplicate at form
  separateLicenseBlock: true,
  kennzeichenZuweisenOption: { value: 1, error: "" }, // Default Selection is the "Reserviertes Wunschkennzeichen" 2nd radio button option
  naechstesKennzeichen: { value: false, error: "" },
  reservieterKundenkreis: { value: true, error: "" },
  pin: { value: "", error: "" },
  MerkmalElektrokennzeichen: { value: false, error: "" },
  ZweiraderKennzeichen: { value: false, error: "" }, // License for Two wheeler
  MerkmalReservierungKennzeichen: { value: false, error: "" },
  Kennzeichen: { value: "", error: "" }, //full license plate for NZ and AB
  kennzeichenF: { value: "" },
  kennzeichenM: { value: "" },
  kennzeichenL: { value: "" },
  sicherheitscode_vorn: { value: "", error: "" },
  sicherheitscode_hinten: { value: "", error: "" },
  reservietesKennzeichenBis: { value: "", error: "" }, //Missing
  UmmeldungOldKennzeichenRetained: { value: "nein", error: "" },
  OldKennzeichen: { value: "", error: "" }, //full license plate for ReRegistration(UM/WZ)
  OldKennzeichenF: { value: "" },
  OldKennzeichenM: { value: "" },
  OldKennzeichenL: { value: "" },
};

const halterDefaultForm = {
  hasError: false,
  hasDuplicate: false,
  formDuplicate: 0, // number of duplicate at form
  entspricht: { value: true, error: "" },
  auskunft_NatuerlichePerson_FamiliennameNachname: { value: "", error: "" },
  vorname: { value: "", error: "" },
  geburtsnameNachname: { value: "", error: "" },
  geschlecht: { value: "", error: "" },
  geburtstag: { value: "", error: "" },
  geburtsort: { value: "", error: "" },
  halterAddress: { value: "", error: "" },
  SchluesselBeruf: { value: "", error: "" },
  NameJuristischePerson: { value: "", error: "" },
  Strasse: { value: "", error: "" },
  Hausnummer: { value: "", error: "" },
  Wohnort: { value: "", error: "" },
  Postleitzahl: { value: "", error: "" },
  gosskundenIDVorhanden: { value: "ja", error: "" }, //Missing
  gosskundenIDSteuer: { value: "", error: "" }, //Missing
  steuerzahlweise: { value: "jährlich", error: "" },
  steuerbefreiung: { value: "", error: "" },
  bankverbindung: { value: false, error: "" }, //Missing. if banking info present then true
  kontoinhaber: { value: "", error: "" },
  nameBankverbindung: { value: "", error: "" },
  iban: { value: "", error: "" },
  bic: { value: "", error: "" },
  Vorhanden: { value: false, error: "" },
  kundennummerdeshalters: { value: "", error: "" },
  dauervollmacht: { value: "", error: "" },
  sepaMandat: { value: "", error: "" },
  //ZBT1 Address fields
  zbt1ZustellungType: { value: "VERSAND", error: "" },
  zbt1AddressType: { value: "", error: "" },
  zbt1IndividualAddresseType: { value: "juristische", error: "" },
  zbt1PersonVorname: { value: "", error: "" },
  zbt1PersonNachname: { value: "", error: "" },
  zbt1Unternehmen: { value: "", error: "" },
  zbt1Strasse: { value: "", error: "" },
  zbt1Stadt: { value: "", error: "" },
  zbt1Hausnummer: { value: "", error: "" },
  zbt1Postleitzahl: { value: "", error: "" },

  //ZBT2 Address fields
  zbt2ZustellungType: { value: "VERSAND", error: "" },
  zbt2AddressType: { value: "", error: "" },
  zbt2IndividualAddresseType: { value: "juristische", error: "" },
  zbt2PersonVorname: { value: "", error: "" },
  zbt2PersonNachname: { value: "", error: "" },
  zbt2Unternehmen: { value: "", error: "" },
  zbt2Strasse: { value: "", error: "" },
  zbt2Stadt: { value: "", error: "" },
  zbt2Hausnummer: { value: "", error: "" },
  zbt2Postleitzahl: { value: "", error: "" },
};

const vollmachtDefaultForm = {
  hasError: false,
  hasDuplicate: false,
  formDuplicate: 0, // number of duplicate at form
  dauervollmacht: { value: "", error: "" },
  sepaMandat: { value: "", error: "" },
  gesetztlicherVertreter: { value: "", error: "" },
  vollmachtsprozess: { value: "Dauervollmacht", error: "" },
  unterzeichnungsprozess: { value: "Qes", error: "" },
  dauervollmachtId: { value: "", error: "" },
};

const getCurrentStatusOfRequest = (object, fieldId) => {
  let fieldObject;
  forEach(object, function (tab) {
    forEach(tab?.value, function (card) {
      forEach(card?.value, function (field) {
        if (field?.id === fieldId) {
          fieldObject = field;
        }
      });
    });
  });
  return fieldObject?.value;
};

const getTabObjects = (tabId, responseObject) => {
  let tabObject = find(responseObject?.data, function (tab) {
    return tab.id === tabId;
  });
  return result(tabObject, "value");
};
const addLicencePlateSpaces = (
  { licensePlateF, licensePlateM, licensePlateL },
  isELicencePlate
) => {
  //If it is E-licence plate then printable characters will be 7 otherwise for usual it will be 8 printable characters
  let printableCharacters = isELicencePlate ? 7 : 8;

  let licensePlateCount = (licensePlateF + licensePlateM + licensePlateL)
    ?.length;

  // Maximum Character in first block can be 3 only
  for (let i = 3 - licensePlateF.length; i > 0; i--) {
    //We have the priority of filling first block before other 2 blocks so we are checking the length of first block
    if (
      licensePlateCount === printableCharacters &&
      licensePlateF.length === 3
    ) {
      break;
    }
    licensePlateF = licensePlateF + " ";
    licensePlateCount++;
  }

  // Maximum Character in middle block can be 2 only
  for (let i = 2 - licensePlateM.length; i > 0; i--) {
    //We have the priority of filling first block before other 2 blocks so we are checking the length of first block
    if (licensePlateCount === printableCharacters && licensePlateF.length < 3) {
      break;
    }
    licensePlateM = licensePlateM + " ";
    licensePlateCount++;
  }

  // Maximum Character in last block can be 4 only
  for (let i = 4 - licensePlateL.length; i > 0; i--) {
    //We have the priority of filling first block before other 2 blocks so we are checking the length of first block
    if (licensePlateCount === printableCharacters && licensePlateF.length < 3) {
      break;
    }
    licensePlateL = " " + licensePlateL;
    licensePlateCount++;
  }
  return (
    licensePlateF +
    licensePlateM +
    licensePlateL +
    `${isELicencePlate ? "E" : ""}`
  );
};

const setAdditonalValueForRequest = (mergedObjectforAllStep) => {
  // set value for Kennzeichenreservierung card
  let option =
    mergedObjectforAllStep["vorgangsart"].value === "AB"
      ? ""
      : mergedObjectforAllStep.kennzeichenZuweisenOption;
  const value = parseInt(option?.value, 10);

  mergedObjectforAllStep["naechstesKennzeichen"].value =
    value === KENNZEICHEN_OPTIONS_0 ? true : false;
  mergedObjectforAllStep["reservieterKundenkreis"].value =
    value === KENNZEICHEN_OPTIONS_1 ? true : false;

  //----------Checking Length and Adding Spaces in License Plate for NZ and AB(Total 9 characters License plate including space)-----------------
  if (value !== KENNZEICHEN_OPTIONS_0) {
    const { kennzeichenF, kennzeichenM, kennzeichenL } = mergedObjectforAllStep;
    const numberPlate = {
      licensePlateF: kennzeichenF?.value.trim(),
      licensePlateM: kennzeichenM?.value.trim(),
      licensePlateL: kennzeichenL?.value.trim(),
    };
    if (mergedObjectforAllStep["MerkmalElektrokennzeichen"].value) {
      //E license plate case
      mergedObjectforAllStep["Kennzeichen"].value = addLicencePlateSpaces(
        numberPlate,
        true
      );
    } else {
      //Usual lisence plate case
      mergedObjectforAllStep["Kennzeichen"].value = addLicencePlateSpaces(
        numberPlate,
        false
      );
    }
  } else {
    mergedObjectforAllStep["Kennzeichen"].value = "";
  }
  //----------Checking Length and Adding Spaces in License Plate for UM/WZ i.e ReRegistration(Total 9 characters License plate including spaces)-----------------
  if (
    ["UM", "WZ", "HA"].includes(mergedObjectforAllStep["vorgangsart"].value)
  ) {
    const { OldKennzeichenF, OldKennzeichenM, OldKennzeichenL } =
      mergedObjectforAllStep;
    const oldNumberPlate = {
      licensePlateF: OldKennzeichenF?.value.trim(),
      licensePlateM: OldKennzeichenM?.value.trim(),
      licensePlateL: OldKennzeichenL?.value.trim(),
    };
    if (mergedObjectforAllStep["MerkmalElektrokennzeichen"].value) {
      //E license plate case
      mergedObjectforAllStep["OldKennzeichen"].value = addLicencePlateSpaces(
        oldNumberPlate,
        true
      );
    } else {
      //Usual lisence plate case
      mergedObjectforAllStep["OldKennzeichen"].value = addLicencePlateSpaces(
        oldNumberPlate,
        false
      );
    }
  } else {
    mergedObjectforAllStep["OldKennzeichen"].value = "";
  }
};

const setFieldedValueInRequestObject = (formData, formObjects) => {
  let formFieldDeafultObject = formData;
  setAdditonalValueForRequest(formObjects);

  //Adding entered value to the corresponding node
  forEach(formFieldDeafultObject.data, function (tab) {
    forEach(tab.value, function (card) {
      forEach(card.value, function (field) {
        forEach(formObjects, function (fieldValue, key) {
          if (key === field.id) {
            field.value = fieldValue.value;
          }
        });
      });
    });
  });
  return formFieldDeafultObject;
};

const licensePlateArray = (licensePlate) => {
  let licensePlateF = "";
  let licensePlateM = "";
  let licensePlateL = "";

  if (!/\s/g.test(licensePlate)) {
    //If license plate does not have any spaces and characters are 9 then it will be divided in 3,2,4 chars for first,middle and last block
    licensePlateF = licensePlate?.substr(0, 3);
    licensePlateM = licensePlate?.substr(3, 2);
    licensePlateL = licensePlate?.substr(5, 4);
  } else {
    //We will get first block of licence plate with encounter of first space and left substring will be used for other blocks
    licensePlateF = licensePlate?.substring(0, licensePlate?.indexOf(" ")); //First license plate block will be retrieved with the splitting of first space
    if (licensePlateF.length > 3) {
      licensePlateF = licensePlateF.substring(0, 3);
    }

    const licensePlateData = licensePlate?.replace(licensePlateF, "");
    // For the middle and last license plate block we have to use the loop and set alphabets in middle block and numeric values in last block
    for (let i = 0; i < licensePlateData.length; i++) {
      if (licensePlateData[i].match(/[a-z]/i)) {
        if (licensePlateM.length === 2) {
          licensePlateL = licensePlateL + licensePlateData[i];
        } else {
          //Checking for alphabets and setting it in middle block
          licensePlateM = licensePlateM + licensePlateData[i];
        }
      } else {
        licensePlateL = licensePlateL + licensePlateData[i];
      }
    }
  }
  return [licensePlateF, licensePlateM?.trim(), licensePlateL?.trim()]; //using trim method for removing extra spaces
};

const setCustomValuesForHalterRequest = (
  elementObject,
  companyDetails,
  personenform
) => {
  elementObject.bankverbindung.value =
    elementObject.bankverbindung.value === "true";

  /*Commented below if condition to support version 1.2
 
  */
  //set Kundennr. bei der Zulassungsstelle vorhanden to nein if any no bank details available
  /*if (
    elementObject.bankverbindung.value ||
    elementObject.kontoinhaber.value ||   
    elementObject.nameBankverbindung.value ||
    elementObject.iban.value ||
    elementObject.bic.value
  ) {
    elementObject.gosskundenIDVorhanden.value = "nein";
  } */
  /*Added below if condition to support version 1.2
  gosskundenIDVorhanden value is ja in case of company persone otherwise nein
  */
  if (personenform.value === formConstants.companyPerson) {
    elementObject.gosskundenIDVorhanden.value = "ja";
  } else {
    elementObject.gosskundenIDVorhanden.value = "nein";
  }

  //Make check box Kontoinhaber*in true or false based on return value and that should not be blank)
  if (
    elementObject?.auskunft_NatuerlichePerson_FamiliennameNachname?.value !==
      "" &&
    elementObject?.vorname?.value !== "" &&
    elementObject?.kontoinhaber?.value ===
      elementObject?.vorname?.value +
        " " +
        elementObject?.auskunft_NatuerlichePerson_FamiliennameNachname?.value
  ) {
    elementObject.bankverbindung.value = true;
  } else {
    elementObject.bankverbindung.value = false;
  }
  //We get this values in string from BE but setting it in boolean for FE checkbox
  elementObject.Vorhanden.value =
    elementObject.Vorhanden?.value === "true" ? true : false;

  return elementObject;
};

const setCustomValuesForKennzeichenRequest = (elementObject) => {
  //for actual and duplicate value
  forEach(["value", "duplicate"], (attr) => {
    //set value of kennzeichenZuweisenOption option based on
    //Nächstes freies Kennzeichen, Reserviertes Wunschkennzeichen
    let optionValue;
    if (elementObject.naechstesKennzeichen[attr] === "true") {
      optionValue = KENNZEICHEN_OPTIONS_0;
    }
    if (elementObject.reservieterKundenkreis[attr] === "true") {
      optionValue = KENNZEICHEN_OPTIONS_1;
    }

    //set duplicate value
    if (optionValue > -1) {
      //possible options are 0,1,2
      elementObject.kennzeichenZuweisenOption[attr] = optionValue;
    }

    //set MerkmalReservierungKennzeichen in boolean. We are getting true or false in string so need to convert in
    // boolean
    if (elementObject.MerkmalReservierungKennzeichen.hasOwnProperty(attr)) {
      elementObject.MerkmalReservierungKennzeichen[attr] =
        elementObject.MerkmalReservierungKennzeichen[attr] === "true"
          ? true
          : false;
    }

    //set eKennezeichen in boolean. We are getting true or false in string so need to convert in
    // boolean
    if (elementObject.MerkmalElektrokennzeichen.hasOwnProperty(attr)) {
      elementObject.MerkmalElektrokennzeichen[attr] =
        elementObject.MerkmalElektrokennzeichen[attr] === "true" ? true : false;
    }

    //set ZweiraderKennzeichen in boolean. We are getting true or false in string so need to convert in
    // boolean
    if (elementObject.ZweiraderKennzeichen.hasOwnProperty(attr)) {
      elementObject.ZweiraderKennzeichen[attr] =
        elementObject.ZweiraderKennzeichen[attr] === "true" ? true : false;
    }

    //set license Plate value into splitted field for NZ
    if (
      elementObject.Kennzeichen.hasOwnProperty(attr) &&
      elementObject.kennzeichenZuweisenOption[attr] !== KENNZEICHEN_OPTIONS_0
    ) {
      let kennzeichenArray = [];
      if (elementObject.MerkmalElektrokennzeichen?.value) {
        //trim appended E from e-license plate
        elementObject.Kennzeichen[attr] = elementObject.Kennzeichen[
          attr
        ].substr(0, elementObject.Kennzeichen[attr].length - 1);
      }
      kennzeichenArray = licensePlateArray(elementObject.Kennzeichen[attr]);

      if (attr === "value") {
        elementObject.kennzeichenF[attr] = kennzeichenArray[0] || "";
        elementObject.kennzeichenM[attr] = kennzeichenArray[1] || "";
        elementObject.kennzeichenL[attr] = kennzeichenArray[2] || "";
      }

      if (attr === "duplicate") {
        if (
          elementObject.kennzeichenZuweisenOption.hasOwnProperty("duplicate") &&
          elementObject.kennzeichenZuweisenOption.value !==
            elementObject.kennzeichenZuweisenOption.duplicate
        ) {
          elementObject.kennzeichenF[attr] = kennzeichenArray[0] || "";
          elementObject.kennzeichenM[attr] = kennzeichenArray[1] || "";
          elementObject.kennzeichenL[attr] = kennzeichenArray[2] || "";
        } else {
          if (elementObject.kennzeichenF["value"] !== kennzeichenArray[0]) {
            elementObject.kennzeichenF[attr] = kennzeichenArray[0] || "";
          }
          if (elementObject.kennzeichenM["value"] !== kennzeichenArray[1]) {
            elementObject.kennzeichenM[attr] = kennzeichenArray[1] || "";
          }
          if (elementObject.kennzeichenL["value"] !== kennzeichenArray[2]) {
            elementObject.kennzeichenL[attr] = kennzeichenArray[2] || "";
          }
        }
        elementObject.separateLicenseBlock = false;
      }

      if (
        elementObject.Kennzeichen.hasOwnProperty("duplicate") &&
        !elementObject.kennzeichenZuweisenOption.hasOwnProperty("duplicate")
      ) {
        if (attr === "duplicate") {
          elementObject.separateLicenseBlock = false;
          elementObject.formDuplicate = elementObject.formDuplicate + 1;
        } else {
          elementObject.separateLicenseBlock = true;
          //elementObject.formDuplicate = elementObject.formDuplicate + 1;
        }
      }

      if (
        !elementObject.Kennzeichen.hasOwnProperty("duplicate") &&
        elementObject.pin.duplicate
      ) {
        elementObject.separateLicenseBlock = false;
        elementObject.formDuplicate++;
      }
    }

    //set license Plate value into splitted field for ReRegistration(UM/WZ) request
    if (elementObject.OldKennzeichen.hasOwnProperty(attr)) {
      let oldKennzeichenArray = [];
      if (elementObject.MerkmalElektrokennzeichen?.value) {
        //trim appended E from e-license plate
        elementObject.OldKennzeichen[attr] = elementObject.OldKennzeichen[
          attr
        ].substr(0, elementObject.OldKennzeichen[attr].length - 1);
      }
      oldKennzeichenArray = licensePlateArray(
        elementObject.OldKennzeichen[attr]
      );

      if (attr === "value") {
        elementObject.OldKennzeichenF[attr] = oldKennzeichenArray[0] || "";
        elementObject.OldKennzeichenM[attr] = oldKennzeichenArray[1] || "";
        elementObject.OldKennzeichenL[attr] = oldKennzeichenArray[2] || "";
      }
    }
  });

  if (elementObject.kennzeichenZuweisenOption.hasOwnProperty("duplicate")) {
    //added count +1 for kennzeichenZuweisenOption value
    elementObject.formDuplicate++;
    elementObject.separateLicenseBlock = true;
  }

  return elementObject;
};

const setCustomValuesForAntragRequest = (elementObject, requestId) => {
  if (requestId !== "") {
    elementObject.referenzID_23.disabled = true;
    elementObject.isVorgangsnummer.display = false; //we need to hide incase of edit from manual and view from details
    if (elementObject.addresse_type?.value === "") {
      elementObject.addresse_type.value = "naturliche"; // Setting default value for zbt2 individual address option name type after save ME api call
    }
  }
  return elementObject;
};

const setCustomFieldsAndValues = (
  elementObject,
  formIndex,
  requestId,
  companyDetails,
  personenform
) => {
  if (formIndex === 0) {
    //set custom field or value for atrang form
    setCustomValuesForAntragRequest(elementObject, requestId);
  }
  if (formIndex === 2) {
    setCustomValuesForKennzeichenRequest(elementObject);
  }
  if (formIndex === 3) {
    setCustomValuesForHalterRequest(
      elementObject,
      companyDetails,
      personenform
    );
  }
  return elementObject;
};

const updateStateObject = (
  refObject,
  responseObject,
  formIndex,
  requestId,
  companyDetails,
  personenform
) => {
  var elementObject = { ...refObject };
  forEach(responseObject, (card) => {
    forEach(card.value, (field) => {
      let fieldId = field.id;
      if (elementObject[fieldId]) {
        elementObject[fieldId].value = field.value;
        elementObject[fieldId].error = field.errorMessage;
        if (field.hasOwnProperty("duplicateValue")) {
          elementObject[fieldId].duplicate = field.duplicateValue;
          if (
            !fieldsNotRequiredForDuplicateCount?.includes(fieldId) &&
            !(fieldId === "pin")
          ) {
            elementObject.formDuplicate++;
          }
        }
        //for error icon in tab
        if (field.errorMessage !== "" && !elementObject.hasError) {
          elementObject.hasError = true;
        }
        //for actualization icon in tab
        if (
          field.hasOwnProperty("duplicateValue") &&
          !elementObject.hasDuplicate
        ) {
          elementObject.hasDuplicate = true;
        }
      }
    });
  });

  setCustomFieldsAndValues(
    elementObject,
    formIndex,
    requestId,
    companyDetails,
    personenform
  );
  return elementObject;
};

const getCurrentFormName = (step) => {
  switch (step) {
    case 0:
      return "antragRequestFormData";
    case 1:
      return "fahrzeugRequestFormData";
    case 2:
      return "kennzeichenRequestFormData";
    case 3:
      return "halterRequestFormData";
    default:
      return "";
  }
};
// QR Code Constants
const qrCode = {
  generateNewQRCode: "new",
};

export {
  qrCode,
  formTag,
  formConstants,
  formfieldData,
  antragDefaultForm,
  fahrzeugDefaultForm,
  kennzeichenDefaultForm,
  halterDefaultForm,
  vollmachtDefaultForm,
  splittedLicenseFields,
  fieldsNotRequiredForDuplicateCount,
  fieldsRequiredForRadioButtons,
  splitedFieldsforLicensePlate,
  kennzeichenReservierungRadioLabel,
  getCurrentStatusOfRequest,
  getTabObjects,
  setAdditonalValueForRequest,
  setFieldedValueInRequestObject,
  licensePlateArray,
  updateStateObject,
  getCurrentFormName,
};
