import * as React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Grid } from "@mui/material";

// Assets
import FehlerSmallIcon from "../iconComponents/FehlerSmallIcon";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function setActiveTabs(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  indicator: {
    backgroundColor: "blue",
    height: "10px",
    top: "45px",
  },
  flexContainer: {
    display: "flex",
    flexWrap: "wrap",
    backgroundColor: "white",
    borderBottom: `2px solid ${theme.palette.MDCColors.color16}`,
    height: "67px",
  },
  root: {
    "&.MuiTab-root": {
      backgroundColor: "black",
      border: 0,
      borderBottom: "2px solid",
      "&:hover": {
        border: 0,
        borderBottom: "2px solid",
      },
    },
  },
  tabPanel: {
    "& .css-19kzrtu": {
      padding: "0 !important",
      marginTop: "2%",
    },
  },
  tabLabel: {
    textTransform: "unset !important",
  },
}));

export default function MDCSecondaryTabs(props) {
  const [value, setValue] = React.useState(0);
  const { navItems } = props;

  const classes = useStyles();

  const handleChange = (event, newValue) => {
    setValue(newValue);
    props.getActiveSecondaryTabValue(newValue);
  };

  const getIcon = (navItem) => {
    if (navItem?.error) {
      return <FehlerSmallIcon />;
    }
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box
        sx={{
          border: "1px solid #E6E6E6",
          padding: "1%",
          opacity: 1,
        }}
      >
        <Tabs value={value} onChange={handleChange} textColor="black">
          {navItems.map((navItem, index) => (
            <Tab
              key={index}
              value={index}
              label={navItem.tabTitle}
              icon={getIcon(navItem)}
              iconPosition={"start"}
              className={classes.tabLabel}
              {...setActiveTabs(index)}
            />
          ))}
        </Tabs>
      </Box>
      <Grid container>
        <Grid item xs={12}>
          {navItems.map((navItem, index) => (
            <TabPanel
              key={index}
              value={value}
              index={index}
              className={classes.tabPanel}
            >
              {navItem.tabContent}
            </TabPanel>
          ))}
        </Grid>
      </Grid>
    </Box>
  );
}
