// Constants
import { MULTI_TENANT_MAPPING_CONSTANTS } from "../../../constants/multiTenantsAccessSettingsConstants";

const initialState = {
  userMappingDetails: [],
  allTenantsWithIds: [],
  groupTenantNames: [],
};

export default (state, action) => {
  if (typeof state === "undefined") {
    state = initialState;
  }
  switch (action.type) {
    case MULTI_TENANT_MAPPING_CONSTANTS.GET_USERS_MAPPED_TO_TENANTS:
      return Object.assign({}, state, {
        userMappingDetails: action.details,
      });
    case MULTI_TENANT_MAPPING_CONSTANTS.ALL_KEYCLOAK_TENANTS:
      return Object.assign({}, state, {
        allTenantsWithIds: action.details,
      });
    case MULTI_TENANT_MAPPING_CONSTANTS.GROUP_AND_TENANT_NAMES:
      return Object.assign({}, state, {
        groupTenantNames: action.details,
      });

    default:
      return state;
  }
};
