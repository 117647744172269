import React, { lazy } from "react";
import momentTimezone from "moment-timezone";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import { connect } from "react-redux";

//CSS
import "../src/styles/main.scss";

//helper
import interceptor from "./store/services/utils/interceptor";
import { history } from "./helpers/history";

// Components
// Administration
const Administration = lazy(() => import("./components/admin/Administration"));

// App Login Dashboard
const AppLoginDashboard = lazy(() =>
  import("./components/appLoginDashboard/AppLoginDashboard")
);

// Dashboard
const Dashboard = lazy(() => import("./components/dashboard/Dashboard"));

// External Vorgang Number
const externalVorgangNumber = lazy(() =>
  import("./components/external/externalVorgangNumber")
);

// Inner Components
import Layout from "./components/layout/Layout";

// Login Page
const LoginPage = lazy(() => import("./components/login/LoginPage"));

// Manual Request Form (Create a Request)
const ManualRequestForm = lazy(() =>
  import("./components/manualRequest/ManualRequestForm")
);

// Tenant Details
const TenantDetails = lazy(() =>
  import("./components/tenantDetails/TenantDetails")
);

// User Profile
const ProfileComponent = lazy(() => import("./components/userProfile/Profile"));

// Broadcast Message
const BroadcastMessage = lazy(() =>
  import("./components/userProfile/BroadcastMessage")
);

// Vollmachtsdatenbank (POA List)
const VollmachtsDatenBank = lazy(() =>
  import("./components/userProfile/VollmachtsDatenBank")
);

import PrivateRoute from "./components/shared/PrivateRoute";

// Register Page
const RegisterPage = lazy(() => import("./components/register/RegisterPage"));

// POA Record With QES
import VollmachtsDatenBankQESProcess from "./components/userProfile/VollmachtsDatenBankQESProcess";

// MDC Theme
import MDCDefaultTheme from "./themes/mdc-theme";
import { ThemeProvider } from "@mui/material/styles";

// Core Components
import MDCLoader from "./components/core/MDCLoader";

// Constants
import {
  USER_ROLE_ADMIN,
  USER_ROLE_APPROVER,
  USER_ROLE_MDC_ADMIN,
  USER_ROLE_SUPPORT,
  USER_ROLE_API_USER,
  USER_ROLE_EXPERT,
  USER_ROLE_ACCOUNTANT,
} from "./constants/userConstants";

// React Date Range CSS
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file

// Set the Default Timezone
momentTimezone.tz.setDefault("Europe/Berlin");

const showContentCenter = {
  position: "absolute",
  top: "50%",
  left: "50%",
  "-webkit-transform": "translate(-50%, -50%)",
  transform: "translate(-50%, -50%)",
};

// External redirect Url
const ExternalRedirect = ({ ...routeProps }) => {
  return (
    <Route
      {...routeProps}
      render={(renderProps) => {
        window.open(renderProps?.location?.state?.redirectUrl, "_self");

        return (
          <div style={showContentCenter}>
            <MDCLoader />
          </div>
        );
      }}
    />
  );
};

class App extends React.Component {
  constructor(props) {
    super(props);
    interceptor();
  }

  componentDidUpdate() {
    if (this.props.authentication?.user) {
      //to be changed
      document.body.style.backgroundColor = "#5f5f5f";
    }
  }

  render() {
    return (
      <ThemeProvider theme={MDCDefaultTheme}>
        <Router history={history}>
          <Layout authentication={this.props.authentication}>
            <Switch>
              <Route
                exact
                path="/"
                render={() => {
                  if (!this.props.authentication?.user) {
                    return <Redirect to="/login" />;
                    // } else if (this.props.authentication?.user) {
                    //   return <Redirect to="/dashboard" />;
                  } else {
                    const isMDCRoles =
                      this.props.authentication?.user?.roles?.filter(
                        (authority) => authority.startsWith("MDC_")
                      ).length;

                    const isAccountantRole =
                      this.props.authentication?.user?.roles?.includes(
                        USER_ROLE_ACCOUNTANT
                      );

                    return isMDCRoles > 0 || isAccountantRole ? (
                      <Redirect to="/app-dashboard" />
                    ) : (
                      <Redirect to="/dashboard" />
                    );
                  }
                }}
              />

              <ExternalRedirect exact path="/logout" />

              <PrivateRoute
                exact
                path="/admin"
                roles={[
                  USER_ROLE_ADMIN,
                  USER_ROLE_MDC_ADMIN,
                  USER_ROLE_SUPPORT,
                  USER_ROLE_API_USER,
                  USER_ROLE_ACCOUNTANT,
                  USER_ROLE_EXPERT,
                ]}
                component={Administration}
              />
              <PrivateRoute
                exact
                path="/app-dashboard"
                roles={[USER_ROLE_MDC_ADMIN, USER_ROLE_ACCOUNTANT]}
                component={AppLoginDashboard}
              />
              <PrivateRoute
                exact
                path="/dashboard"
                roles={[
                  USER_ROLE_ADMIN,
                  USER_ROLE_APPROVER,
                  USER_ROLE_API_USER,
                  USER_ROLE_MDC_ADMIN,
                  USER_ROLE_SUPPORT,
                  USER_ROLE_EXPERT,
                ]}
                component={Dashboard}
              />
              <PrivateRoute
                path="/external/:version/vorgang/:primaryReference"
                component={externalVorgangNumber}
              />
              <Route exact path="/login" component={LoginPage} />
              <Route exact path="/register" component={RegisterPage} />
              <PrivateRoute
                exact
                path="/manual-request"
                component={ManualRequestForm}
                roles={[
                  USER_ROLE_ADMIN,
                  USER_ROLE_APPROVER,
                  USER_ROLE_API_USER,
                  USER_ROLE_MDC_ADMIN,
                  USER_ROLE_SUPPORT,
                  USER_ROLE_EXPERT,
                ]}
              />

              <PrivateRoute
                exact
                path="/tenant"
                roles={[USER_ROLE_MDC_ADMIN, USER_ROLE_ACCOUNTANT]}
                component={TenantDetails}
              />
              <PrivateRoute
                exact
                path="/profile"
                component={ProfileComponent}
              />
              <PrivateRoute
                exact
                path="/broadcastMessage"
                roles={[USER_ROLE_MDC_ADMIN]}
                component={BroadcastMessage}
              />

              <PrivateRoute
                path="/vollmachtsdatenbank/qes"
                component={VollmachtsDatenBankQESProcess}
              />

              <PrivateRoute
                exact
                path="/vollmachtsdatenbank"
                component={VollmachtsDatenBank}
              />
              <Route
                exact
                path="/register-popup"
                render={(routeProps) => (
                  <Dashboard
                    showRegisterPopup
                    errorMessage={routeProps?.location?.state?.errorMessage}
                    loginStatusCode={
                      routeProps?.location?.state?.loginStatusCode
                    }
                    tenantVertragsCheckKey={
                      routeProps?.location?.state?.tenantVertragsCheckKey
                    }
                  />
                )}
              />
              <Redirect from="*" to="/" />
            </Switch>
          </Layout>
        </Router>
      </ThemeProvider>
    );
  }
}

function mapState(state) {
  const { authentication } = state;
  return { authentication };
}

const connectedApp = connect(mapState)(App);

export { connectedApp as App };
