import * as React from "react";

function RightArrowIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="5.843"
      height="10.303"
      viewBox="0 0 5.843 10.303"
    >
      <path
        id="Icon_awesome-caret-right"
        data-name="Icon awesome-caret-right"
        d="M0,17.153V8.239a.693.693,0,0,1,1.183-.49L5.64,12.206a.693.693,0,0,1,0,.98L1.183,17.643A.693.693,0,0,1,0,17.153Z"
        transform="translate(0 -7.545)"
        fill="#00c4de"
      />
    </svg>
  );
}

export default RightArrowIcon;
